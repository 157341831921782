import React, {useState, useContext, useEffect, useRef} from 'react';
import chimera from '../../chimera';
import FormFieldMicro from '../FormFieldMicro';
import AddressDisplay from '../CustomerPage/AddressDisplay';
import PocDisplay from '../CustomerPage/PocDisplay';
import StringList from '../StringList';
import BannerContext, {BannerLog} from '../BannerLogContext';
import Modal, {choiceCancel, choiceDelete} from '../Modal';
import LoadingSpinner from '../LoadingSpinner';
import { NewCustomerBody } from '../NewCustomer';
import ModalContext from '../ModalContext';
import MiniNotes from '../MiniNotes';
import EmailModal from '../EmailModal';
import AlertsContext from "../AlertsContext";
import Autocomplete from '../Autocomplete';
import UserContext from '../../UserContext';
import DelayModal from './DelayModal';
import IPListTable from '../CustomerPage/IPListTable';
import ModalCancelOnly from '../ModalCancelOnly';

/**
 *  @param props.type the enum string (see InternetOrder schema) 
 *  @param props.order the object being edited. If left undefined or null, POST requests will be used on save instead of PUT.
 * */
const InternetOrderFormBody = props => {
    const [createButtonDisabled, setCreateButtonDisabled] = useState((props.workingOrder.customerRef ? true : false) || props.workingOrder.status !== "Provisioned");
    const [markAsCompleted, setMarkAsCompleted] = useState(false);
    const [ipLists, setIpLists] = useState(null);
    const [ipClaims, setIpClaims] = useState({});
    const banners = useContext(BannerContext);
    const modaling = useContext(ModalContext);
    const alertsContext = useContext(AlertsContext);
    const userContext = useContext(UserContext);
    const firstRender = useRef(true);

    const getCustomer = () => {
        const customer = props.customers.find((customer) => customer.accountNumber === props.workingOrder.customerRef);
        return customer;
    }

    const getLocationIndex = () => {
        const customer = getCustomer();
        if(!customer) return 0;
        for(let i = 0; i < customer.locations.length; i++) {
            if(customer.locations[i].nickname === props.workingOrder.customerLocationNickname) return i;
        }
        return 0;
    }

    useEffect(() => {
        console.log(ipClaims);
    }, [ipClaims]);

    useEffect(() => {
        if(firstRender.current) {
            firstRender.current = false;
            return;
        }
        else if(props.type !== "New Customer") {
            const customer = getCustomer();
            if(customer) {
                const newOrder = JSON.parse(JSON.stringify(props.workingOrder));

                if(props.workingOrder.customerLocationNickname === "ADD_NEW_LOCATION") {
                    newOrder.serviceAddress = chimera.DEFAULT_ADDRESS;
                    newOrder.businessPhone = '';
                    newOrder.provider = 'NOT SET';
                    newOrder.poc = chimera.DEFAULT_POC;
                    newOrder.billingPoc = customer.billingPocs[0];
                    newOrder.billingAddress = customer.billingAddress;
                    newOrder.technical = {
                        cids: [],
                        ips: [],
                        subnetMasks: [],
                        gateways: [],
                        equipmentLocation: '',
                        servicePort: '',
                        emailSentDate: ''
                    }
                }
                else {
                    const locationIndex = getLocationIndex();

                    newOrder.serviceAddress = customer.locations[locationIndex].serviceAddress;
                    newOrder.businessPhone = customer.locations[locationIndex].businessPhone;
                    newOrder.service = customer.locations[locationIndex].technical.service ? customer.locations[locationIndex].technical.service : 'NOT SET';
                    newOrder.provider = customer.locations[locationIndex].technical.provider ? customer.locations[locationIndex].technical.provider : 'NOT SET';
                    newOrder.poc = customer.locations[locationIndex].poc;
                    newOrder.billingPoc = customer.billingPocs[0];
                    newOrder.billingAddress = customer.billingAddress;
                    newOrder.technical = {
                        cids: customer.locations[locationIndex].technical.cids,
                        ips: customer.locations[locationIndex].technical.ips,
                        subnetMasks: customer.locations[locationIndex].technical.subnetMasks,
                        gateways: customer.locations[locationIndex].technical.gateways,
                        equipmentLocation: customer.locations[locationIndex].technical.equipmentLocation,
                        servicePort: customer.locations[locationIndex].technical.servicePort,
                        emailSentDate: customer.locations[locationIndex].technical.emailSentDate
                    }
                }

                props.setWorkingOrder(newOrder);
                props.setModified(true);
            }
        }
    }, [props.workingOrder.customerLocationNickname, props.workingOrder.customerRef]);

    useEffect(() => {
        if(ipLists === null) {
            chimera.callAPI(undefined, '/api/iplists')
            .then(newLists => setIpLists(newLists))
            .catch(err => {
                console.error(err);
                banners.addBanner('danger', 'Failed to read IP Lists - POP selection will be unavailable.', 'Error');
            })
        }
    }, [ipLists]);

    const checkLocked = () => {
        if(userContext.permissions.admin.write && !props.isSaving) return false;
        if(props.isSaving) return true;
        switch(props.type) {
            case 'New Customer':
                return props.workingOrder.customerRef !== "" || props.workingOrder.status === "Delayed" || props.workingOrder.status === "Cancelled";
            default:
                return props.workingOrder.status === "Delayed" || props.workingOrder.status === "Cancelled";
        }
    }

    const locked = checkLocked();

    const trimOnBlur = (event) => {
        handleChange({
            target: {
                type: "string",
                name: event.target.name,
                value: event.target.value.trim()
            },
            preventDefault: () => {}
        })
    }
    
    const handleChange = (event) => {
        if(event.target.type !== "checkbox") event.preventDefault();
        props.setModified(true);
        const name = event.target.name;
        const value = event.target.type !== "checkbox" ? event.target.value : event.target.checked;
        let newWorkingOrder = JSON.parse(JSON.stringify(props.workingOrder));
        
        if(name === "retailStatus") {
            if(newWorkingOrder.status !== "Completed" && newWorkingOrder.status !== "Cancelled" && newWorkingOrder.status !== "Delayed") {
                if(value === "SO Signed") {
                    newWorkingOrder.status = "In-Progress";
                }
                else if(value === "Service Info Sent") {
                    newWorkingOrder.status = "Provisioned";
                    newWorkingOrder.billStartDate = new Date().toISOString().substring(0,10);
                    setCreateButtonDisabled(false);
                }
                else {
                    newWorkingOrder.status = "New";
                }
            }
        }
        else if(name === "contractTerm") {
            if(value === "NOT SET" || value === "Month-to-Month") {
                newWorkingOrder.renewalDate = '';
            }
            else {
                let nYears = parseInt(value.split(' ')[0]);
                let billStartDateYear = parseInt(newWorkingOrder.billStartDate.split('-')[0]);
                newWorkingOrder.renewalDate = `${billStartDateYear + nYears}-${newWorkingOrder.billStartDate.substring(5)}`;
            }
        }
        else if(name === "markAsCompleted") {
            setMarkAsCompleted(value);
            if(value) {
                newWorkingOrder.status = "Completed";
            }
            else {
                newWorkingOrder.status = "Provisioned";
            }
        }
        else if(name === "assignee") {
            const selectedUser = props.users.find((user) => user.email === value);
            if(selectedUser) {
                newWorkingOrder.assignee = selectedUser;
            }
        }

        if(event.target.type === "number") {
            chimera.setAttr(newWorkingOrder, name, parseFloat(value));
        }
        else if(name.toLowerCase().includes("phone")) {
            chimera.setAttr(newWorkingOrder, name, value.replace(/\D/g, ''));
        }
        else if(name !== "markAsCompleted" && name !== "assignee") {
            chimera.setAttr(newWorkingOrder, name, value);
        }

        props.setWorkingOrder(newWorkingOrder);
    }

    const handleSaveError = (err) => {
        console.error(err);
        if(err.details && err.details.name === "ValidationError") {
            for(const key in err.details.errors) {
                banners.addBanner('danger', err.details.errors[key].message, 'Validation Error');
            }
        }
        else if(err.name !== 'AbortError') {
            banners.addBanner('danger', 'Failed to save order', 'Error');
        }
    }

    const isManualEntry = () => {
        return !props.workingOrder.technical.dhcp && (props.workingOrder.provider !== 'COR' && props.workingOrder.provider !== 'CBIT');
    }

    const saveIPClaims = async(newOrder) => {
        // TODO: Handle IP claims.
        console.log('IN saveIPClaims()');

        // The IPClaims should only have 1 key at a time... right?
        // So instead of futsing with that we can just get whatever value belongs to the first key in either case.
        // TODO: claimedEntries does not always depend on the InternetOrder. Handle case where Customer is already the claimant
        // TODO: Do not support editing/saving when the IP Table is for the Customer.
        

        let tableClaimedIds = [];
        for(const key in ipClaims) {
            tableClaimedIds = ipClaims[key];
            break;
        }

        if(newOrder.customerRef) {
            const claimedEntries = await chimera.callAPI(undefined, '/api/iplists/getclaims', 'POST', {
                stamp: {
                    objectType: 'InternetOrder',
                    objectId: newOrder._id,
                    name: `Internet Order for ${newOrder.customerName}`
                }
            })
            let claimedIds = [];
            if(claimedEntries.length === 0) {
                // use table actually
                claimedIds = tableClaimedIds;
            }
            else {
                claimedIds = claimedEntries.map(entry => entry._id);
            }

            // Remove claims for the Internet Order, then
            // Claim for the Customer.
            
            let selectedCustomer = getCustomer();
            if(!selectedCustomer) {
                selectedCustomer = await chimera.callAPI(undefined, `/api/customers/accountnumber/${newOrder.customerRef}`);
            }
            let locationIndex = -1;
            if(newOrder.type === "New Customer") {
                locationIndex = 0;
            }
            else {
                for(let i = 0; i < selectedCustomer.locations.length; i++) {
                    if(selectedCustomer.locations[i].nickname === newOrder.customerLocationNickname || selectedCustomer.locations[i].nickname === newOrder.newLocationNickname) {
                        locationIndex = i;
                        break;
                    }
                }
            }
    
            if(locationIndex === -1 && newOrder.customerLocationNickname !== "ADD_NEW_LOCATION") {
                console.error('Failed to save IP claims because locationIndex returned -1 still');
                return;
            }
            
            if(claimedEntries.length > 0) {
                await chimera.callAPI(undefined, '/api/iplists/claim', 'POST', {
                    ids: [],
                    stamp: {
                        objectType: 'InternetOrder',
                        objectId: newOrder._id,
                        name: `Internet Order for ${newOrder.customerName}`
                    }
                })
            }
            await chimera.callAPI(undefined, '/api/iplists/claim', 'POST', {
                ids: claimedIds,
                stamp: {
                    objectType: 'CustomerLocation',
                    objectId: selectedCustomer.locations[locationIndex]._id,
                    name: selectedCustomer.displayName
                }
            })
        }
        else {
            // Claim for the InternetOrder.
            await chimera.callAPI(undefined, '/api/iplists/claim', 'POST', {
                ids: tableClaimedIds,
                stamp: {
                    objectType: 'InternetOrder',
                    objectId: newOrder._id,
                    name: `Internet Order for ${newOrder.customerName}`
                }
            })
        }
    }

    const handleSave = (event, order) => {
        if(event) event.preventDefault();
        const resolve = (savedOrder) => {
            if(props.saveMode === 'PUT') {
                banners.addBanner('info', `Changes saved successfully.`, 'Saved');
            }
            else { // saveMode === 'POST'
                banners.addBanner('info', `The Internet Order${savedOrder.customerName ? ` for ${savedOrder.customerName}` : ""} was created successfully.`, 'Success');
                props.setSaveMode('PUT');
            }
            if(!isManualEntry()) {
                saveIPClaims(savedOrder);
            }
            props.setWorkingOrder(savedOrder);
            props.setSavedOrder(savedOrder);
            alertsContext.reload(); // Saved changes may dismiss an alert so we refresh
        }
        props.handleSave(resolve, handleSaveError, order);
    }

    const handleUpdateCustomer = () => {
        const controller = new AbortController();
        const signal = controller.signal;
        const dismiss = (event) => {
            event.preventDefault();
            controller.abort();
            modaling.setModal(null);
        }

        const selectedCustomer = getCustomer();
        if(!selectedCustomer) {
            banners.addBanner('danger', 'The selected customer could not be determined. Please notify the site administrator about this issue.', 'Customer Error');
            return;
        }
        const customer = JSON.parse(JSON.stringify(selectedCustomer));
        let locationIndex = -1;
        for(let i = 0; i < selectedCustomer.locations.length; i++) {
            if(selectedCustomer.locations[i].nickname === props.savedOrder.customerLocationNickname || selectedCustomer.locations[i].nickname === props.savedOrder.newLocationNickname) {
                locationIndex = i;
                break;
            }
        }

        if(locationIndex === -1 && props.savedOrder.customerLocationNickname !== "ADD_NEW_LOCATION") {
            banners.addBanner('danger', 'The selected location could not be found on the associated customer. Please notify the site administrator about this issue.', 'Location Error');
            return;
        }

        const trimCustomer = (cust, includeKeyFunc) => {
            let ret = {};
            const excludeKeys = [
                "_id", "__v", "createdAt", "createdBy",
                "modifiedBy", "updatedAt", "accountNumber"
            ];
            for(const key in cust) {
                if(!excludeKeys.includes(key) && (includeKeyFunc === undefined || includeKeyFunc(key))) {
                    ret[key] = cust[key];
                }
            }
            return ret;
        }

        if(props.savedOrder.customerLocationNickname === "ADD_NEW_LOCATION" && locationIndex === -1) {
            locationIndex = customer.locations.length;
            customer.locations = [...customer.locations, chimera.DEFAULT_LOCATION];
            customer.locations[locationIndex].nickname = props.savedOrder.newLocationNickname;
            customer.locations[locationIndex].shippingAddress = props.savedOrder.serviceAddress;
        }

        let notAlreadyInternet = !customer.locations[locationIndex].serviceTypes.internet;

        customer.displayName = props.savedOrder.customerName;
        customer.businessName = props.savedOrder.customerName;
        customer.locations[locationIndex].serviceTypes.internet = true;
        customer.locations[locationIndex].serviceAddress = props.savedOrder.serviceAddress;
        customer.locations[locationIndex].businessPhone = props.savedOrder.businessPhone;
        customer.billingPhone = props.savedOrder.billingPoc.phone;
        customer.locations[locationIndex].poc = props.savedOrder.poc;
        customer.billingAddress = props.savedOrder.billingAddress;
        customer.billingPocs = [props.savedOrder.billingPoc];
        customer.locations[locationIndex].technical.equipmentLocation = props.savedOrder.technical.equipmentLocation;
        customer.locations[locationIndex].technical.servicePort = props.savedOrder.technical.servicePort;
        customer.locations[locationIndex].technical.cids = props.savedOrder.technical.cids;
        customer.locations[locationIndex].technical.ips = props.savedOrder.technical.ips;
        customer.locations[locationIndex].technical.subnetMasks = props.savedOrder.technical.subnetMasks;
        customer.locations[locationIndex].technical.gateways = props.savedOrder.technical.gateways;
        customer.locations[locationIndex].technical.emailSentDate = props.savedOrder.technical.emailSentDate;
        customer.locations[locationIndex].technical.service = props.savedOrder.service;
        customer.locations[locationIndex].technical.provider = props.savedOrder.provider;
        customer.locations[locationIndex].technical.dhcp = props.savedOrder.technical.dhcp;
        
        const loadingModal = <Modal choices={[]} dismiss={dismiss}>
            <BannerLog>
                <LoadingSpinner size={50} label={`Updating ${customer.displayName}...`}/>
            </BannerLog>
        </Modal>
        modaling.setModal(loadingModal);

        chimera.callAPI(signal, `/api/customers/${customer._id}`, 'PUT', trimCustomer(customer))
        .then(updatedCustomer => {
            // If ADD_NEW_LOCATION or if the selected location did not have Internet service type yet, auto-claim an IP based on the selected POP in the order.
            if(notAlreadyInternet && !props.savedOrder.technical.dhcp) {
                let ipList = ipLists.find(list => list.pop === props.savedOrder.technical.pop);
                if(ipList) {
                    let firstUnclaimedIp = ipList.list.find(entry => entry.claims.length === 0);
                    if(firstUnclaimedIp) {
                        chimera.callAPI(undefined, '/api/iplists/claim', 'POST', {
                            ids: [firstUnclaimedIp._id],
                            stamp: {
                                objectType: 'CustomerLocation',
                                objectId: updatedCustomer.locations[locationIndex]._id,
                                name: updatedCustomer.displayName
                            }
                        })
                        .catch(err => {
                            console.error(err);
                            banners.addBanner('warning', 'Could not auto-assign IPs. Please go to the Customer page to assign their IP(s).', 'Warning');
                        })
                    }
                    else {
                        banners.addBanner('warning', 'Could not auto-assign IPs. Please go to the Customer page to assign their IP(s).', 'Warning');
                    }
                }
            }

            modaling.backtrack();
            banners.addBanner('info', `Saved changes to location ${customer.locations[locationIndex].nickname} for ${customer.displayName} in Chimera.`, 'Success');
        })
        .catch(err => {
            console.error(err);
            modaling.backtrack();
            if(err.details.name === "ValidationError") {
                for(const key in err.details.errors) {
                    const error = err.details.errors[key];
                    if(error.path === "billingPhone") {
                        banners.addBanner('danger', `The Billing POC's Phone Number is required because it is used as the location's Billing Phone, a required field.`, 'Validation Error');
                    }
                    else {
                        banners.addBanner('danger', error.message, 'Validation Error');
                    }
                }
            }
            else {
                banners.addBanner('danger', 'Failed to update the customer.', 'Error');
            }
        })
    }

    const handleDisconnect = () => {
        const controller = new AbortController();
        const signal = controller.signal;
        const dismiss = (event) => {
            event.preventDefault();
            controller.abort();
            modaling.setModal(null);
        }

        const selectedCustomer = getCustomer();
        if(!selectedCustomer) {
            banners.addBanner('danger', 'The selected customer could not be determined. Please notify the site administrator about this issue.', 'Customer Error');
            return;
        }
        let locationIndex = -1;
        for(let i = 0; i < selectedCustomer.locations.length; i++) {
            if(selectedCustomer.locations[i].nickname === props.savedOrder.customerLocationNickname) {
                locationIndex = i;
                break;
            }
        }

        if(locationIndex === -1) {
            banners.addBanner('danger', 'The selected location could not be found on the associated customer. Please notify the site administrator about this issue.', 'Location Error');
            return;
        }

        const trimCustomer = (cust, includeKeyFunc) => {
            let ret = {};
            const excludeKeys = [
                "_id", "__v", "createdAt", "createdBy",
                "modifiedBy", "updatedAt", "accountNumber"
            ];
            for(const key in cust) {
                if(!excludeKeys.includes(key) && (includeKeyFunc === undefined || includeKeyFunc(key))) {
                    ret[key] = cust[key];
                }
            }
            return ret;
        }

        const customer = new chimera.CommercialCustomer(selectedCustomer, locationIndex);
        customer.displayName = props.savedOrder.customerName;
        customer.businessName = props.savedOrder.customerName;
        customer.serviceTypes.internet = false;
        customer.serviceAddress = props.savedOrder.serviceAddress;
        customer.businessPhone = props.savedOrder.businessPhone;
        customer.billingPhone = props.savedOrder.billingPoc.phone;
        customer.poc = props.savedOrder.poc;
        customer.billingAddress = props.savedOrder.billingAddress;
        customer.billingPocs = [props.savedOrder.billingPoc];
        customer.technical.equipmentLocation = props.savedOrder.technical.equipmentLocation;
        customer.technical.servicePort = props.savedOrder.technical.servicePort;
        customer.technical.cids = props.savedOrder.technical.cids;
        customer.technical.ips = props.savedOrder.technical.ips;
        customer.technical.subnetMasks = props.savedOrder.technical.subnetMasks;
        customer.technical.gateways = props.savedOrder.technical.gateways;
        customer.technical.emailSentDate = props.savedOrder.technical.emailSentDate;
        customer.technical.service = props.savedOrder.service;
        customer.technical.provider = props.savedOrder.provider;
        customer.technical.dhcp = props.savedOrder.technical.dhcp;
        
        const loadingModal = <Modal choices={[]} dismiss={dismiss}>
            <BannerLog>
                <LoadingSpinner size={50} label={`Disconnecting ${customer.displayName}...`}/>
            </BannerLog>
        </Modal>
        modaling.setModal(loadingModal);

        chimera.callAPI(signal, `/api/customers/${customer._id}`, 'PUT', trimCustomer(customer._rawCustomerData))
        .then(_ => {
            modaling.backtrack();
            banners.addBanner('info', `Saved changes to location ${customer.locations[locationIndex].nickname} for ${customer.displayName} in Chimera.`, 'Success');
        })
        .catch(err => {
            console.error(err);
            modaling.backtrack();
            if(err.details.name === "ValidationError") {
                for(const key in err.details.errors) {
                    const error = err.details.errors[key];
                    if(error.path === "billingPhone") {
                        banners.addBanner('danger', `The Billing POC's Phone Number is required because it is used as the location's Billing Phone, a required field.`, 'Validation Error');
                    }
                    else {
                        banners.addBanner('danger', error.message, 'Validation Error');
                    }
                }
            }
            else {
                banners.addBanner('danger', 'Failed to update the customer.', 'Error');
            }
        })
    }

    const handleCreate = (event) => {
        event.preventDefault();
        if(props.type !== "New Customer" && props.type !== "Disconnect") {
            handleUpdateCustomer();
            return;
        }
        else if(props.type === "Disconnect") {
            handleDisconnect();
            return;
        }
        const controller = new AbortController();
        const signal = controller.signal;
        const dismiss = (event) => {
            event.preventDefault();
            controller.abort();
            modaling.setModal(null);
        }

        const customer = new chimera.CommercialCustomer();
        customer.displayName = props.savedOrder.customerName;
        customer.businessName = props.savedOrder.customerName;
        customer.serviceTypes.internet = true;
        customer.serviceAddress = props.savedOrder.serviceAddress;
        customer.businessPhone = props.savedOrder.businessPhone;
        customer.billingPhone = props.savedOrder.billingPoc.phone;
        customer.poc = props.savedOrder.poc;
        customer.billingAddress = props.savedOrder.billingAddress;
        customer.billingPocs = [props.savedOrder.billingPoc];
        customer.technical.equipmentLocation = props.savedOrder.technical.equipmentLocation;
        customer.technical.servicePort = props.savedOrder.technical.servicePort;
        customer.technical.cids = props.savedOrder.technical.cids;
        customer.technical.ips = props.savedOrder.technical.ips;
        customer.technical.subnetMasks = props.savedOrder.technical.subnetMasks;
        customer.technical.gateways = props.savedOrder.technical.gateways;
        customer.technical.emailSentDate = props.savedOrder.technical.emailSentDate;
        customer.technical.service = props.savedOrder.service;
        customer.technical.provider = props.savedOrder.provider;
        customer.technical.dhcp = props.savedOrder.technical.dhcp;
        
        const createdCustomerCallback = (createdCustomer) => {

            /** 
            if(!props.workingOrder.technical.dhcp) {
                let ipList = ipLists.find(list => list.pop === props.workingOrder.technical.pop);
                if(ipList) {
                    let firstUnclaimedIp = ipList.list.find(entry => entry.claims.length === 0);
                    if(firstUnclaimedIp) {
                        chimera.callAPI(undefined, '/api/iplists/claim', 'POST', {
                            ids: [firstUnclaimedIp._id],
                            stamp: {
                                objectType: 'CustomerLocation',
                                objectId: createdCustomer.locations[0]._id,
                                name: createdCustomer.displayName
                            }
                        })
                        .catch(err => {
                            console.error(err);
                            banners.addBanner('warning', 'Could not auto-assign IPs. Please go to the Customer page to assign their IP(s).', 'Warning');
                        })
                    }
                    else {
                        banners.addBanner('warning', 'Could not auto-assign IPs. Please go to the Customer page to assign their IP(s).', 'Warning');
                    }
                }
            }
                */

            chimera.callAPI(undefined, `/api/internetorders/${props.workingOrder._id}`, 'PUT', {customerRef: createdCustomer.accountNumber})
            .then(newOrder => {
                saveIPClaims(newOrder);
                props.setSavedOrder(newOrder);
                props.setWorkingOrder(newOrder);
                setCreateButtonDisabled(true);
                modaling.backtrack();
            })
            .catch(err => {
                console.error(err);
                modaling.backtrack();
                banners.addBanner('danger', 'Failed to save customer reference to this Order. Please alert the website administrator so that the customer reference can be manually linked.', 'Error');
            })
        }

        const modal = <Modal choices={[]} dismiss={dismiss}>
            <BannerLog>
                <NewCustomerBody customer={customer} controller={controller} signal={signal} createdCustomerCallback={createdCustomerCallback} hideIPTable/>
            </BannerLog>
        </Modal>
        modaling.setModal(modal);
    }

    const statusColor = (status) => {
        switch(status) {
            case "New":
                return "info";
            case "In-Progress":
                return "warning";
            case "Provisioned":
                return "primary";
            case "Completed":
                return "secondary"; //"success";
            case "Cancelled":
                return "danger";
            case "Delayed":
                return "dark"; //"secondary";
            default:
                return "light";
        }
    }

    const setNotes = (newNotes) => {
        props.setModified(true);
        const newWorkingOrder = JSON.parse(JSON.stringify(props.workingOrder));
        newWorkingOrder.notes = newNotes;
        props.setWorkingOrder(newWorkingOrder);
    }

    const handleUndelay = (event) => {
        event.preventDefault();
        const newWorkingOrder = JSON.parse(JSON.stringify(props.workingOrder));
        newWorkingOrder.status = newWorkingOrder.storedStatus;
        newWorkingOrder.storedStatus = "";
        newWorkingOrder.delayUntilDate = "";
        props.setWorkingOrder(newWorkingOrder);
        handleSave(null, newWorkingOrder);
    }

    const openEmailModal = async(event) => {
        event.preventDefault();
        const callback = () => {
            const newWorkingOrder = JSON.parse(JSON.stringify(props.workingOrder));
            newWorkingOrder.technical.emailSentDate = new Date();

            const resolve = (savedOrder) => {
                props.setWorkingOrder(savedOrder);
                props.setSavedOrder(savedOrder);
                props.setSaveMode('PUT');
            }

            const reject = (err) => {
                console.error(err);
            }

            props.handleSave(resolve, reject, newWorkingOrder);
        }

        let ips = [];
        let subnetMasks = [];
        let gateways = [];
        if(!isManualEntry()) {
            modaling.setModal(
                <ModalCancelOnly context={modaling}>
                    <LoadingSpinner size={75}/>
                </ModalCancelOnly>
            )

            const claimedEntries = await chimera.callAPI(undefined, '/api/iplists/getclaims', 'POST', {
                stamp: {
                    objectType: props.workingOrder.customerRef ? 'CustomerLocation' : 'InternetOrder',
                    objectId: props.workingOrder.customerRef ? getCustomerClaimaintID() : props.workingOrder._id,
                    name: props.workingOrder.customerRef ? props.workingOrder.customerName : `Internet Order for ${props.workingOrder.customerName}`
                }
            })

            ips = claimedEntries.map(entry => entry.ip);
            subnetMasks = claimedEntries.map(entry => entry.subnetMask);
            gateways = claimedEntries.map(entry => entry.gateway);

            modaling.backtrack();
        }
        else {
            ips = props.workingOrder.technical.ips;
            subnetMasks = props.workingOrder.technical.subnetMasks;
            gateways = props.workingOrder.technical.gateways;
        }
        

        /** NOTE: CPEs were removed because they aren't part of the schema. Should they be added? */
        modaling.setModal(<EmailModal callback={callback} to={props.workingOrder.poc.email} modalContext={modaling} backtrack subject={props.type === "Disconnect" ? 'Your Internet Service Disconnect is Now Complete' : `Your ${props.workingOrder.service} Fiber Internet Service is Now Available!`} text={
            props.type === "Disconnect" ? 
            `${props.workingOrder.customerName},\n\nYour fiber internet service disconnect is now complete.\n
If you have any questions or issues please reach out and we will be happy to help you!\n
Business Hours:
Monday-Friday
7am-4:30pm

Tech Support:
support@gocbit.com
(509) 578-5424 option 2

Billing:
accounting@gocbit.com
(509) 578-5424 option 3`
            :
            `${props.workingOrder.customerName},\n\nThank you for choosing CBIT! Your fiber internet service is now available.\n
Please see your service information below: \n
Equipment Location:\n${props.workingOrder.technical.equipmentLocation}\n
Service Port:\n${props.workingOrder.technical.servicePort}\n
CID(s):\n${props.workingOrder.technical.cids.join('\n')}\n
IP(s):\n${ips.join('\n')}\n
Subnet Mask(s):\n${subnetMasks.join('\n')}\n
Gateway(s):\n${gateways.join('\n')}\n
If you have any questions or issues please reach out and we will be happy to help you!\n
Business Hours:
Monday-Friday
7am-4:30pm

Tech Support:
support@gocbit.com
(509) 578-5424 option 2

Billing:
accounting@gocbit.com
(509) 578-5424 option 3`
        }/>);
    }

    const suggestionChosenCallback = (suggestion) => {
        const customer = props.customers.find((customer) => customer.displayName === suggestion);
        if(customer) {
            const newOrder = JSON.parse(JSON.stringify(props.workingOrder));
            newOrder.customerName = customer.displayName;
            newOrder.customerRef = customer.accountNumber;
            props.setWorkingOrder(newOrder);
            props.setModified(true);
        }
        else {
            banners.addBanner('danger', 'Something went wrong with selecting the customer.', 'Error');
        }
    }

    const disableCreateButton = () => {
        if(locked) return true;

        if(props.type === "New Customer") {
            return createButtonDisabled || !props.saveDisabled
        }
        else {
            return props.workingOrder.status !== "Completed" || !chimera.deepEqual(props.workingOrder, props.savedOrder);
        }
    }

    const createButtonLabel = () => {
        switch(props.type) {
            case 'New Customer':
                return 'Create Customer';
            case 'Disconnect':
                return 'Disconnect Location';
            default:
                return 'Update Customer';
        }
    }

    const locationOptions = () => {
        const customer = getCustomer();
        if(!customer) return [];
        let options = customer.locations.map((location) => { return {id: location.nickname, value: location.nickname}});
        if(props.type === "Modify Service") {
            options = [...options, {id: 'ADD_NEW_LOCATION', value: '+ Add Location...'}];
        }
        return options;
    }

    const openDelayModal = (event) => {
        event.preventDefault();
        const callback = () => {
            modaling.backtrack();
            props.setWorkingOrder(null);
        }
        modaling.setModal(<DelayModal orders={[props.workingOrder]} bannerContext={banners} callback={callback}/>)
    }

    const getCustomerClaimaintID = () => {
        const selectedCustomer = getCustomer();
        if(!selectedCustomer) {
            console.warn('Failed to get Claimant ID because new customer not in stored list. Refresh and try again');
            return '';
        }
        let locationIndex = -1;
        if(props.workingOrder.type === "New Customer") {
            locationIndex = 0;
        }
        else {
            for(let i = 0; i < selectedCustomer.locations.length; i++) {
                if(selectedCustomer.locations[i].nickname === props.workingOrder.customerLocationNickname || selectedCustomer.locations[i].nickname === props.workingOrder.newLocationNickname) {
                    locationIndex = i;
                    break;
                }
            }
        }

        if(locationIndex === -1 && props.workingOrder.customerLocationNickname !== "ADD_NEW_LOCATION") {
            console.error('Failed to get Claimant ID because locationIndex is still -1');
            return '';
        }
        else {
            return selectedCustomer.locations[locationIndex]._id;
        }
    }

    return(
        <div className="row">
            <div className="col-9 border-end">
                <h3>Internet Order: {props.type}</h3>
                <h4>Customer Details</h4>
                <div className="row row-cols-1 row-cols-md-4 g-4">
                    {/** Customer name, service, address, business phone */}
                    <FormFieldMicro
                        type="select"
                        name="service"
                        label="Service"
                        value={props.workingOrder.service}
                        handleChange={handleChange}
                        options={[
                            {id: "100 Mbps", value: "100 Mbps"},
                            {id: "200 Mbps", value: "200 Mbps"},
                            {id: "250 Mbps", value: "250 Mbps"},
                            {id: "500 Mbps", value: "500 Mbps"},
                            {id: "1 Gbps", value: "1 Gbps"},
                            {id: "2 Gbps", value: "2 Gbps"},
                            {id: "30 Gbps", value: "30 Gbps"}
                        ]}
                        disabled={locked}
                    />
                    {props.type === "New Customer" ? 
                    <FormFieldMicro
                        type="text"
                        name="customerName"
                        label="Customer Name"
                        value={props.workingOrder.customerName}
                        handleChange={handleChange}
                        onBlur={trimOnBlur}
                        disabled={locked}
                    />
                    :
                    <>
                    <div className="col">
                        <Autocomplete
                            label="Customer Name"
                            value={props.workingOrder.customerName}
                            suggestions={props.customers.map(customer => customer.displayName)}
                            suggestionChosenCallback={suggestionChosenCallback}
                            strictMode
                            disabled={locked}
                        />
                    </div>
                    <div className="col">
                        <FormFieldMicro
                            type="select"
                            name="customerLocationNickname"
                            label="Location"
                            value={props.workingOrder.customerLocationNickname}
                            handleChange={handleChange}
                            options={locationOptions()}
                            excludeNoneSelected
                            disabled={locked}
                            fullWidth
                        />
                    </div>
                    {props.workingOrder.customerLocationNickname === 'ADD_NEW_LOCATION' ? 
                    <div className="col">
                        <FormFieldMicro
                            type="text"
                            name="newLocationNickname"
                            label="New Location Name"
                            value={props.workingOrder.newLocationNickname}
                            handleChange={handleChange}
                            onBlur={trimOnBlur}
                            disabled={locked}
                        />
                    </div>
                    :null}
                    </>
                    }
                    <FormFieldMicro
                        type="text"
                        name="businessPhone"
                        label="Business Phone"
                        value={props.workingOrder.businessPhone}
                        handleChange={handleChange}
                        onBlur={trimOnBlur}
                        disabled={locked}
                    />
                    {props.type === "New Customer" ? <div className="col"/> : null}
                    <div className="col w-50">
                        <FormFieldMicro
                            type="component"
                            name="serviceAddress"
                            label="Service Address"
                            disabled={locked}
                        >
                            <AddressDisplay addr={props.workingOrder.serviceAddress} basePath="serviceAddress" baseValue={props.workingOrder} onChange={handleChange} isEditing onBlur={trimOnBlur} disabled={locked}/>
                        </FormFieldMicro>
                    </div>
                </div>
                <hr/>
                <h4>Service Info</h4>
                {/** POC, Provider, FOC Date, Retail Status, Wholesale Status */}
                <div className="row row-cols-1 row-cols-md-4 g-4">
                    <FormFieldMicro
                        type="select"
                        name="provider"
                        label="Provider"
                        value={props.workingOrder.provider}
                        handleChange={handleChange}
                        options={[
                            {id: "BPUD", value: "BPUD"},
                            {id: "Noanet", value: "Noanet"},
                            {id: "FPUD", value: "FPUD"},
                            {id: "COR", value: "COR"},
                            {id: "CBIT", value: "CBIT"}
                        ]}
                        disabled={locked}
                    />
                    <FormFieldMicro
                        type="date"
                        name="focDate"
                        label="FOC Date"
                        value={props.workingOrder.focDate}
                        handleChange={handleChange}
                        disabled={locked}
                    />
                    <FormFieldMicro
                        type="select"
                        name="wholesaleStatus"
                        label="Wholesale Status"
                        value={props.workingOrder.wholesaleStatus}
                        handleChange={handleChange}
                        options={[
                            {id: "No Contract", value: "No Contract"},
                            {id: "Quote Requested", value: "Quote Requested"},
                            {id: "Quote Received", value: "Quote Received"},
                            {id: "SO Requested", value: "SO Requested"},
                            {id: "SO Received", value: "SO Received"},
                            {id: "SO Signed", value: "SO Signed"},
                            {id: "SOA Received", value: "SOA Received"},
                            {id: "N/A", value: "N/A"},
                        ]}
                        disabled={locked}
                    />
                    <FormFieldMicro
                        type="select"
                        name="retailStatus"
                        label="Retail Status"
                        value={props.workingOrder.retailStatus}
                        handleChange={handleChange}
                        options={[
                            {id: "Waiting on Customer", value: "Waiting on Customer"},
                            {id: "Quote Sent", value: "Quote Sent"},
                            {id: "Quote Accepted", value: "Quote Accepted"},
                            {id: "SO Sent", value: "SO Sent"},
                            {id: "SO Signed", value: "SO Signed"},
                            {id: "Service Info Sent", value: "Service Info Sent"},
                        ]}
                        disabled={locked}
                    />
                    <div className="col w-50">
                    <FormFieldMicro
                        type="component"
                        name="poc"
                        label="Point of Contact"
                        disabled={locked}
                    >
                        <PocDisplay poc={props.workingOrder.poc} basePath="poc" baseValue={props.workingOrder} onChange={handleChange} isEditing onBlur={trimOnBlur} disabled={locked}/>
                    </FormFieldMicro>
                    </div>
                </div>
                <hr/>
                <h4>Billing Info</h4>
                {/** Billing Address, POC, MRC, NRC, Bill Start Date */}
                <div className="row row-cols-1 row-cols-md-4 g-4">
                    <div className="col w-50">
                    <FormFieldMicro
                        type="component"
                        name="billingPoc"
                        label="Billing POC"
                        disabled={locked}
                    >
                        <PocDisplay poc={props.workingOrder.billingPoc} basePath="billingPoc" baseValue={props.workingOrder} onChange={handleChange} isEditing onBlur={trimOnBlur} disabled={locked}/>
                    </FormFieldMicro>
                    </div>
                    <div className="col w-50">
                    <FormFieldMicro
                        type="component"
                        name="billingAddress"
                        label="Billing Address"
                        disabled={locked}
                    >
                        <AddressDisplay addr={props.workingOrder.billingAddress} basePath="billingAddress" baseValue={props.workingOrder} onChange={handleChange} isEditing onBlur={trimOnBlur} disabled={locked}/>
                    </FormFieldMicro>
                    </div>
                    <FormFieldMicro
                        type="number"
                        name="mrc"
                        label="MRC"
                        value={props.workingOrder.mrc}
                        handleChange={handleChange}
                        disabled={locked}
                    />
                    <FormFieldMicro
                        type="number"
                        name="nrc"
                        label="NRC"
                        value={props.workingOrder.nrc}
                        handleChange={handleChange}
                        disabled={locked}
                    />
                    <div/>
                    <div/>
                    <FormFieldMicro
                        type="date"
                        name="billStartDate"
                        label="Bill Start Date"
                        value={props.workingOrder.billStartDate}
                        handleChange={handleChange}
                        disabled={locked}
                    />
                    <FormFieldMicro
                        type="select"
                        name="contractTerm"
                        label="Contract Term"
                        value={props.workingOrder.contractTerm}
                        handleChange={handleChange}
                        options={[
                            {id: "Month-to-Month", value: "Month-to-Month"},
                            {id: "1 Year", value: "1 Year"},
                            {id: "2 Years", value: "2 Years"},
                            {id: "3 Years", value: "3 Years"},
                            {id: "5 Years", value: "5 Years"},
                        ]}
                        disabled={locked}
                    />
                    {props.workingOrder.contractTerm !== "Month-to-Month" && props.workingOrder.contractTerm !== "NOT SET" ?
                    <>
                    {userContext.permissions.admin.write ? 
                        <FormFieldMicro
                            type="date"
                            name="renewalDate"
                            label="RenewalDate"
                            value={props.workingOrder.renewalDate}
                            handleChange={handleChange}
                            disabled={props.isSaving}
                        />
                    :
                        <div className="col text-start">
                            <span><b>Renewal Date:</b>&nbsp;{new Date(props.workingOrder.renewalDate).toLocaleDateString()}</span>
                        </div>
                    }
                    <FormFieldMicro
                        type="checkbox"
                        name="renewalApproved"
                        label="Renewal Finalized?"
                        value={props.workingOrder.renewalApproved}
                        handleChange={handleChange}
                        disabled={locked}
                    />
                    </>
                    :null}
                </div>
                <hr/>
                <h4>Internet Info</h4>
                {/** CIDs, IPs, Subnet Masks, Gateways */}
                <div className="row mb-2">
                    <div className="col">
                    <button className="btn btn-primary w-fit" onClick={openEmailModal} disabled={props.type === "New Customer" && props.workingOrder.status === "Completed"}>
                        <i className="fas fa-envelope"/>&nbsp;{props.workingOrder.technical.emailSentDate ? "Send Customer Info Again" : "Send Customer Info"}
                    </button>
                    {props.workingOrder.technical.emailSentDate ? <i className="text-muted ms-2">Email was sent to customer on {(new Date(props.workingOrder.technical.emailSentDate)).toLocaleString()}</i> : null}
                    </div>
                </div>
                <div className="row row-cols-1 row-cols-md-4 g-4">
                    <FormFieldMicro
                        type="text"
                        name="technical.equipmentLocation"
                        label="Equipment Location"
                        value={props.workingOrder.technical.equipmentLocation}
                        handleChange={handleChange}
                        onBlur={trimOnBlur}
                        disabled={locked}
                    />
                    <FormFieldMicro
                        type="text"
                        name="technical.servicePort"
                        label="Service Port"
                        value={props.workingOrder.technical.servicePort}
                        handleChange={handleChange}
                        onBlur={trimOnBlur}
                        disabled={locked}
                    />
                    <FormFieldMicro
                        type="checkbox"
                        name="technical.dhcp"
                        label="DHCP?"
                        value={props.workingOrder.technical.dhcp}
                        handleChange={handleChange}
                        disabled={locked}
                        checkLabel=""
                    />
                    {ipLists ? 
                    <FormFieldMicro
                        type="select"
                        name="technical.pop"
                        label="POP"
                        value={props.workingOrder.technical.pop}
                        handleChange={handleChange}
                        options={ipLists.map(list => {return {id: list.pop, value: list.pop}})}
                        disabled={locked}
                    />
                    :
                    <LoadingSpinner size={25} label="Loading POP selection..."/>
                    }
                    <FormFieldMicro
                        type="component"
                        name="technical.cids"
                        label="CIDs"
                        disabled={locked}
                    >
                        <StringList obj={props.workingOrder} basePath="technical.cids" handleChange={handleChange} disabled={locked}/>
                    </FormFieldMicro>
                    {isManualEntry() ? 
                    <>
                    <FormFieldMicro
                        type="component"
                        name="technical.ips"
                        label="IPs"
                        disabled={locked}
                    >
                        <StringList obj={props.workingOrder} basePath="technical.ips" handleChange={handleChange} disabled={locked}/>
                    </FormFieldMicro>
                    <FormFieldMicro
                        type="component"
                        name="technical.subnetMasks"
                        label="Subnet Masks"
                        disabled={locked}
                    >
                        <StringList obj={props.workingOrder} basePath="technical.subnetMasks" handleChange={handleChange} disabled={locked}/>
                    </FormFieldMicro>
                    <FormFieldMicro
                        type="component"
                        name="technical.gateways"
                        label="Gateways"
                        disabled={locked}
                    >
                        <StringList obj={props.workingOrder} basePath="technical.gateways" handleChange={handleChange} disabled={locked}/>
                    </FormFieldMicro>
                    </>
                    :null}
                </div>
                {!isManualEntry() ? 
                <div className="row">
                    <div className="col">
                        <IPListTable modifyCallback={() => {props.setModified(true)}} claims={ipClaims} setClaims={setIpClaims} claimantObjectType={props.workingOrder.customerRef ? 'CustomerLocation' : 'InternetOrder'} claimantId={props.workingOrder.customerRef ? getCustomerClaimaintID() : props.workingOrder._id} isEditing={true} disabled={locked}/>
                    </div>
                </div>
                :null}
            </div>
            <div className="col-3">
                {/** Status, Actionees, Notes, Author, Timestamp(s) */}
                <div className={`alert alert-${statusColor(props.workingOrder.status)} w-100 p-1`}>
                    {props.workingOrder.status}
                </div>
                <button className="btn btn-primary w-100 mb-1" onClick={handleSave} disabled={props.saveDisabled}>
                    <i className={props.isSaving ? "fas fa-spinner" : "fas fa-floppy-disk"}/>&nbsp;{props.isSaving ? "Saving..." : "Save"}
                </button>
                <button className="btn btn-success w-100 mb-1" onClick={handleCreate} disabled={disableCreateButton()}>
                    <i className="fas fa-plus"/>&nbsp;{createButtonLabel()}
                </button>
                {props.workingOrder.status === "Delayed" ?
                <>
                <button className="btn btn-warning w-100 mb-1" onClick={handleUndelay} disabled={props.isSaving}>
                    <i className="fas fa-unlock"/>&nbsp;Un-Delay
                </button>
                <p>
                    <b>This order has been delayed until {new Date(props.workingOrder.delayUntilDate).toLocaleDateString()}</b>
                </p>
                </>
                :
                <button className="btn btn-dark w-100 mb-1" onClick={openDelayModal} disabled={props.isSaving}>
                    <i className="fas fa-truck-arrow-right"/>&nbsp;Delay
                </button>
                }
                {(props.workingOrder.status === "Provisioned" && props.workingOrder.customerRef !== "") || (props.workingOrder.status === "Completed" && !props.saveDisabled && (!props.savedOrder || props.savedOrder.status !== "Completed")) ?
                <FormFieldMicro
                    type="checkbox"
                    name="markAsCompleted"
                    label="Mark as Completed?"
                    value={markAsCompleted}
                    handleChange={handleChange}
                    disabled={props.isSaving}
                />
                :null}
                {props.workingOrder.customerRef ? 
                    <div>
                        <a href={`/customers/${props.workingOrder.customerRef}`} rel="noreferrer" target="_blank">
                            <i className="fas fa-arrow-right"/>
                            &nbsp;Open Customer in New Tab
                        </a>
                    </div>
                :null}
                <FormFieldMicro
                    type="select"
                    name="assignee"
                    label="Assignee"
                    value={props.workingOrder.assignee.email}
                    handleChange={handleChange}
                    disabled={locked}
                    options={props.users.filter((user) => user.groups.includes("Executive")).map((user) => { return {id: user.email, value: `${user.first} ${user.last}`}})}
                    fullWidth
                />
                {props.workingOrder.author ? 
                    <p className="text-muted">Order created by {props.workingOrder.author.first} {props.workingOrder.author.last} ({props.workingOrder.author.email}) at {(new Date(props.workingOrder.createdAt)).toLocaleString()}</p>
                : null}
                <MiniNotes notes={props.workingOrder.notes} setNotes={setNotes} disabled={props.isSaving}/>
            </div>
        </div>
    )
}

const InternetOrderFormModal = props => {
    const [workingOrder, setWorkingOrder] = useState(props.orderId ? null : {
        type: props.type,
        status: "New",
        customerRef: '',
        customerName: '',
        customerLocationNickname: '',
        newLocationNickname: '',
        service: 'NOT SET',
        serviceAddress: {
            street1: '',
            street2: '',
            city: '',
            state: 'WA',
            county: '',
            zip: ''
        },
        businessPhone: '',
        poc: {
            firstName: '',
            lastName: '',
            email: '',
            phone: ''
        },
        provider: 'BPUD',
        focDate: new Date().toISOString().substring(0,10),
        wholesaleStatus: 'NOT SET',
        retailStatus: 'NOT SET',
        billingAddress: {
            street1: '',
            street2: '',
            city: '',
            state: 'WA',
            county: '',
            zip: ''
        },
        billingPoc: {
            firstName: '',
            lastName: '',
            email: '',
            phone: ''
        },
        mrc: 0,
        nrc: 0,
        billStartDate: new Date().toISOString().substring(0,10),
        actionees: [],
        contractTerm: 'NOT SET',
        renewalDate: '',
        renewalApproved: false,
        checkInDate: '',
        technical: {
            cids: [],
            ips: [],
            subnetMasks: [],
            gateways: [],
            equipmentLocation: "",
            servicePort: "",
            pop: 'NOT SET',
            dhcp: false,
            emailSentDate: null
        },
        assignee: {
            first: '',
            last: '',
            email: ''
        },
        notes: []
    });
    const [type, setType] = useState(props.type ? props.type : null);
    const [savedOrder, setSavedOrder] = useState(null);
    const [modified, setModified] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [saveMode, setSaveMode] = useState(props.orderId ? 'PUT' : 'POST');
    const [users, setUsers] = useState(null);
    const [customers, setCustomers] = useState(null);
    const modaling = useContext(ModalContext);

    useEffect(() => {
        if(props.orderId && workingOrder === null) {
            chimera.callAPI(undefined, `/api/internetorders/${props.orderId}`)
            .then(order => {
                setWorkingOrder(order);
                setSavedOrder(order);
                setType(order.type);
            })
        }
    }, [props.orderId, workingOrder]);

    useEffect(() => {
        if(users === null) {
            chimera.callAPI(undefined, '/api/users')
            .then(users => setUsers(users))
            .catch(err => {
                console.error(err);
                alert("Failed to read users. Won't be able to select Assignee");
                setUsers([]);
            })
        }
    }, [users]);

    useEffect(() => {
        if(customers === null) {
            chimera.callAPI(undefined, '/api/customers')
            .then(customers => setCustomers(customers))
            .catch(err => {
                console.error(err);
                alert("Failed to read customers. Cannot open form.");
            })
        }
    }, [customers]);

    const saveDisabled = () => {
        if(isSaving) return true;
        if(saveMode === 'PUT') {
            return chimera.deepEqual(workingOrder, savedOrder) && !modified;
        }
        else {
            return !modified;
        }
    }

    const handleSave = (resolve, reject, order) => {
        //event.preventDefault();
        setIsSaving(true);
        let body = JSON.parse(JSON.stringify(order ? order : workingOrder));
        chimera.callAPI(undefined, `/api/internetorders${saveMode === "PUT" ? `/${order ? order._id : workingOrder._id}` : ''}`, saveMode, body)
        .then(savedOrder => {
            setModified(false);
            setIsSaving(false); // this line present in both clauses BEFORE the resolve/reject is called prevents memory leaks if resolve/reject unmounts the component
            if(resolve) resolve(savedOrder);
        })
        .catch(err => {
            setIsSaving(false);
            if(reject) reject(err);
        });
    }

    const saveAndCloseFunc = (resolve, reject) => {
        const dismiss = (event) => {
            event.preventDefault();
            modaling.backtrack();
        }
        modaling.setModal(<Modal choices={[]} dismiss={dismiss}>
            <LoadingSpinner size={75}/>
        </Modal>)
        handleSave(resolve, reject);
    }

    const saveAndCloseResolve = (savedOrder) => {
        setWorkingOrder(savedOrder);
        setSavedOrder(savedOrder);
        setSaveMode('PUT');
        modaling.setModal(null);
    }

    const saveAndCloseReject = (err) => {
        console.error(err);
        alert("ERROR: Failed to save changes");
        modaling.backtrack();
    }

    const choices = [
        choiceCancel(props, !saveDisabled(), <>
            <h3>You have unsaved changes</h3>
            <p>Are you sure you want to close this form? Your changes will not be saved.</p>
        </>, "Close", {func: saveAndCloseFunc, resolve: saveAndCloseResolve, reject: saveAndCloseReject})
    ]

    if(saveMode === 'PUT') {
        if(savedOrder && savedOrder.status !== "Cancelled" && savedOrder.status !== "Delayed") {
            choices.push({
                btnColor: 'dark',
                btnInner: <span><i className="fas fa-ban"/>&nbsp;Mark as Cancelled</span>,
                func: (e) => {
                    e.preventDefault();
                    const newWorkingOrder = JSON.parse(JSON.stringify(workingOrder));
                    newWorkingOrder.storedStatus = newWorkingOrder.status;
                    newWorkingOrder.status = "Cancelled";
                    handleSave(
                        (savedOrder) => {
                            setWorkingOrder(savedOrder);
                            setSavedOrder(savedOrder);
                        },
                        (err) => {
                            console.error(err);
                            alert("ERROR: Failed to mark as Cancelled");
                        },
                        newWorkingOrder
                    )
                }
            })
        }
        else if(savedOrder && savedOrder.status !== "Delayed"){
            choices.push({
                btnColor: 'dark',
                btnInner: <span><i className="fas fa-star"/>&nbsp;Restore (Un-Cancel)</span>,
                func: (e) => {
                    e.preventDefault();
                    const newWorkingOrder = JSON.parse(JSON.stringify(workingOrder));
                    newWorkingOrder.status = newWorkingOrder.storedStatus;
                    newWorkingOrder.storedStatus = '';
                    handleSave(
                        (savedOrder) => {
                            setWorkingOrder(savedOrder);
                            setSavedOrder(savedOrder);
                        },
                        (err) => {
                            console.error(err);
                            alert("ERROR: Failed to restore the order");
                        },
                        newWorkingOrder
                    )
                }
            })
        }
        choices.push(choiceDelete(props, () => {
            modaling.setModal(<Modal choices={[]} dismiss={(e) => {e.preventDefault(); modaling.setModal(null)}}>
                <LoadingSpinner size={75}/>
            </Modal>);
            chimera.callAPI(undefined, `/api/internetorders/${savedOrder._id}`, 'DELETE')
            .then(_ => {
                props.banners.addBanner('info', 'The order has been deleted.', 'Success');
            })
            .catch(err => {
                console.error(err);
                //alert("ERROR: Failed to delete the order");
                props.banners.addBanner('danger', 'Failed to delete the order.', 'Error');
            })
            .finally(() => {
                props.onClose();
                modaling.setModal(null);
            })
        }, {label: "Delete Permanently"}))
    }

    return (
        <Modal choices={choices} dismiss={choices[0].func}>
            <BannerLog>
                {workingOrder && users && customers ? 
                <InternetOrderFormBody {...props} 
                    workingOrder={workingOrder} setWorkingOrder={setWorkingOrder} 
                    savedOrder={savedOrder} setSavedOrder={setSavedOrder}
                    modifed={modified} setModified={setModified}
                    isSaving={isSaving} setIsSaving={setIsSaving}
                    saveMode={saveMode} setSaveMode={setSaveMode}
                    saveDisabled={saveDisabled()}
                    handleSave={handleSave}
                    users={users}
                    customers={customers}
                    type={type}
                />
                : <LoadingSpinner size={50}/>}
            </BannerLog>
        </Modal>
    )
}

export default InternetOrderFormModal;