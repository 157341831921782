import React, {useState, useEffect, useLayoutEffect, useContext} from 'react';

import {useParams} from 'react-router-dom';
import LoadingSpinner from '../LoadingSpinner';
import chimera from '../../chimera';
import PocDisplay from './PocDisplay';
import AddressDisplay from './AddressDisplay';

import UserContext from '../../UserContext';
import BannerContext, { BannerLog } from "../BannerLogContext";
import Modal from '../Modal';
import ModalContext from '../ModalContext';
import { NavLink } from "react-router-dom";
import IntegrationsDisplay from './IntegrationsDisplay';
import PushSync from '../PushSync';
import NotesSection from './NotesSection';
import StringList from '../StringList';
import EmailModal from '../EmailModal';
import ExpandableDropdown from '../ExpandableDropdown';
import IntegrationForm from './IntegrationForm';
import NewLocationForm from './NewLocationForm';
import UnityDIDList from './UnityDIDList';
import UnityFaxList from './UnityFaxList';
import UnityDevicesCounter from './UnityDevicesCounter';
import OpenBalancePastDue from './OpenBalancePastDue';
import UnsentInvoices from './UnsentInvoices';
import IPListTable from './IPListTable';
import SetActiveModal from '../SetActiveModal';
import ModalCancelOnly from '../ModalCancelOnly';

const CustomerPageBody = props => {
    const { acct } = useParams();
    const [customer, setCustomer] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [workingCustomer, setWorkingCustomer] = useState(null);
    const [saveBtnIcon, setSaveBtnIcon] = useState("fas fa-bookmark");
    const [saveBtnLabel, setSaveBtnLabel] = useState("Save");
    const [isSaving, setIsSaving] = useState(false);
    const [notes, setNotes] = useState(null);
    const [controller] = useState(new AbortController());
    const [signal] = useState(controller.signal);
    const [tags, setTags] = useState(null);
    const [reloadToggle, reloadComponent] = useState(false);
    const [ipClaims, setIpClaims] = useState({});

    const context = useContext(UserContext);
    const banners = useContext(BannerContext);
    const modalContext = useContext(ModalContext);

    useLayoutEffect(() => {
        return () => {
            controller.abort();
        }
    }, []);

    useEffect(() => {
        if(customer) {
            setWorkingCustomer(chimera.CommercialCustomer.copy(customer));
        }
        else {
            setWorkingCustomer(new chimera.CommercialCustomer());
        }
    }, [customer]);

    useEffect(() => {
        if(acct && customer === null) {
            chimera.CommercialCustomer.getByAccountNumber(acct, signal)
            .then(customer => {
                setCustomer(customer);
            })
            .catch(err => {
                if(err.name !== "AbortError") {
                    console.error(err);
                    banners.addBanner('danger', 'Failed to read Customer', 'Error');
                }
            })
        }
    }, [acct, customer]);

    useEffect(() => {
        if(customer && notes === null) {
            const sortByStarred = (arr) => {
                let starredNotes = [];
                let unstarredNotes = [];
                for(const note of arr) {
                    if(note.starred) starredNotes.push(note);
                    else unstarredNotes.push(note);
                }
                return [].concat(starredNotes, unstarredNotes);
            }
            chimera.callAPI(signal, `/api/notes/refId/${customer._id}`)
            .then(newNotes => {setNotes(sortByStarred(newNotes))})
            .catch(e => {
                if(e.name !== "AbortError") {
                    banners.addBanner('danger', 'Could not read notes', 'Error');
                    console.error(e);
                }
            });
        }
    }, [customer, notes]);

    useEffect(() => {
        if(tags === null) {
            chimera.callAPI(signal, '/api/tags')
            .then(newTags => setTags(newTags))
            .catch(err => {
                console.error(err);
                banners.addBanner('danger', 'Failed to read Tags. Tag display and editing will be disabled. Reload the page to try again.', 'Error');
            })
        }
    }, [tags]);

    const handleChange = event => {
        if(event.target.type !== "checkbox") event.preventDefault();
        const name = event.target.name;
        const value = event.target.value;

        let newWorkingCustomer = chimera.CommercialCustomer.copy(workingCustomer);

        if(event.target.type === "checkbox") {
            if(name === "standing") {
                newWorkingCustomer.standing = event.target.checked ? "good" : "bad";
            }
            else if(name.includes('tag-check')) {
                const tagIndex = event.target.name.split('tag-check')[1];
                const tagId = tags[tagIndex]._id;
                if(event.target.checked && !workingCustomer.tags.includes(tagId)) {
                    newWorkingCustomer.tags = [...workingCustomer.tags, tagId];
                }
                else if(workingCustomer.tags.includes(tagId)) {
                    newWorkingCustomer.tags = workingCustomer.tags.filter(id => id !== tagId);
                }
            }
            else {
                chimera.setAttr(newWorkingCustomer, name, event.target.checked);
            }
        }
        else if(event.target.type === "number" && name.includes("endpointPackages")) {
            chimera.setAttr(newWorkingCustomer, name, parseInt(value));
        }
        else if(event.target.type === "number") {
            chimera.setAttr(newWorkingCustomer, name, parseFloat(value));
        }
        else {
            if(name.toLowerCase().includes("phone")) {
                chimera.setAttr(newWorkingCustomer, name, value.replace(/\D/g, ''));
            }
            else {
                chimera.setAttr(newWorkingCustomer, name, value);
            }
        }

        if(name === 'technical.pop') {
            newWorkingCustomer.technical.ipList = [];
        }

        setWorkingCustomer(newWorkingCustomer);
    }

    const confirmDelete = event => {
        event.preventDefault();
        const loadingModal = 
            <Modal choices={[]} dismiss={(event) => {event.preventDefault(); modalContext.setModal(null)}}>
                <LoadingSpinner size={75}/>
            </Modal>;
        const choices = [
            {
                btnColor: 'danger',
                btnInner: <span><i className="fas fa-times"/>&nbsp;Delete From Chimera Only</span>,
                func: async(e) => {
                    e.preventDefault();
                    modalContext.setModal(loadingModal);
                    let bannersCopy = [];
                    if(await chimera.deleteCustomer(customer, {chimera: true}, banners, bannersCopy)) {
                        bannersCopy.push({
                            type: 'info',
                            message: 'Successfully deleted the customer from Chimera.'
                        });
                        modalContext.setModal(null);
                        sessionStorage.setItem("banners", JSON.stringify(bannersCopy));
                        window.open(`/customers`, '_self');
                    }
                }
            },
            {
                btnColor: 'danger',
                btnInner: <span><i className="fas fa-times"/>&nbsp;Delete Everywhere</span>,
                func: async(e) => {
                    e.preventDefault();
                    modalContext.setModal(loadingModal);
                    let bannersCopy = [];
                    if(await chimera.deleteCustomer(customer, {everywhere: true}, banners, bannersCopy)) {
                        bannersCopy.push({
                            type: 'info',
                            message: 'Successfully deleted the customer from Chimera.'
                        });
                        modalContext.setModal(null);
                        sessionStorage.setItem("banners", JSON.stringify(bannersCopy));
                        window.open(`/customers`, '_self');
                    }
                }
            },
            {
                btnColor: 'secondary',
                btnInner: <span><i className="fas fa-arrow-left"/>&nbsp;No, Cancel</span>,
                func: (e) => {
                    e.preventDefault();
                    modalContext.setModal(null);
                }
            }
        ]
        const modal = <Modal choices={choices} dismiss={choices[1].func}>
            <h3>Are you sure?</h3>
            <p><strong>
                Are you sure you want to delete {customer.displayName}? <span className="text-danger">This operation cannot be undone.</span>
            </strong></p>
        </Modal>
        modalContext.setModal(modal);
    }

    const startEditing = event => {
        event.preventDefault();
        setIsEditing(true);
    }

    const cancelEditing = event => {
        event.preventDefault();
        setWorkingCustomer(chimera.CommercialCustomer.copy(customer));
        setIsEditing(false);
    }

    const saveChanges = async event => {
        event.preventDefault();
        setIsSaving(true);
        setSaveBtnIcon("fas fa-spinner");
        setSaveBtnLabel("Saving...");
        banners.clearBanners();

        const trimCustomer = (cust, includeKeyFunc) => {
            let ret = {};
            const excludeKeys = [
                "_id", "__v", "createdAt", "createdBy",
                "modifiedBy", "updatedAt", "accountNumber"
            ];
            for(const key in cust) {
                if(!excludeKeys.includes(key) && (includeKeyFunc === undefined || includeKeyFunc(key))) {
                    ret[key] = cust[key];
                }
            }
            return ret;
        }

        const newCustomer = trimCustomer(workingCustomer.rawCustomerData, (key) => !chimera.deepEqual(customer.rawCustomerData[key], workingCustomer.rawCustomerData[key]));

        const restoreCustomer = async(id, platform) => {
            try {
                await chimera.callAPI(signal, `/api/customers/${id}`, 'PUT', trimCustomer(customer.rawCustomerData));
                return true;
            }
            catch(e) {
                if(e.name !== "AbortError") {
                    console.error(e);
                    banners.addBanner('danger', <span><b>Desync Error:</b> The Chimera changes stuck and could not be reversed, but {platform} failed to update. The records are now desynchronized.</span>);
                }
                return false;
            }
        }

        const save = async(newCustomer) => {
            try {
                const customerResponse = new chimera.CommercialCustomer(await chimera.callAPI(signal, `/api/customers/${workingCustomer._id}`, 'PUT', newCustomer));
                // At this point, the user is confirmed to have permissions to make the changes.
    
                if(!chimera.isDeveloperMode()) {
                    // Propagate to Syncro
                    if(customerResponse.integrationIds.syncro) {
                        try {
                            await chimera.pushToSyncro(customerResponse);
                        }
                        catch(e) {
                            console.error(e);
                            const restored = await restoreCustomer(customerResponse._id, 'Syncro');
                            if(restored) {
                                try {
                                    if(e.status === 422 && e.details && e.details.data.message && e.details.data.message[0] === "Email has already been taken") {
                                        banners.addBanner('danger', <span><b>Syncro Error:</b> You can't use that POC Email since it is already taken for another Syncro customer.</span>);
                                    }
                                    else {
                                        banners.addBanner('danger', <span><b>Syncro Error:</b> Something went wrong and the Syncro customer could not be updated. The Chimera customer will not update.{e.status ? ` (status ${e.status})` : null}</span>);
                                    }
                                }
                                catch(e) {
                                    banners.addBanner('danger', <span><b>Syncro Error:</b> Something went wrong and the Syncro customer could not be updated. The Chimera customer will not update.</span>);
                                }
                            }
                            setIsSaving(false);
                            setSaveBtnIcon("fas fa-bookmark");
                            setSaveBtnLabel("Save");
                            return;
                        }
                    }
    
                    // Propagate to QuickBooks
                    if(customerResponse.integrationIds.quickbooks) {
                        try {
                            // Unlike Syncro, we don't send the whole Customer over no matter what.
                            // Instead, we will check for changes field by field.
                            // This way, we are less likely to overwrite something critial since QB is more sensitive than Syncro.
                            const qbCustomer = {};
                            if(customerResponse.displayName !== customer.displayName) {
                                qbCustomer.DisplayName = customerResponse.displayName;
                            }
                            if(customerResponse.businessName !== customer.businessName) {
                                qbCustomer.CompanyName = customerResponse.businessName;
                            }
                            if(!chimera.deepEqual(customerResponse.billingAddress, customer.billingAddress)) {
                                qbCustomer.BillAddr = {
                                    CountrySubDivisionCode: customerResponse.billingAddress.state,
                                    City: customerResponse.billingAddress.city,
                                    PostalCode: customerResponse.billingAddress.zip,
                                    Line1: customerResponse.billingAddress.street1,
                                    Country: "USA"
                                }
                                if(customerResponse.billingAddress.street2) {
                                    qbCustomer.BillAddr.Line2 = customerResponse.billingAddress.street2;
                                }
                            }
                            if(!chimera.deepEqual(customerResponse.shippingAddress, customer.shippingAddress)) {
                                qbCustomer.ShipAddr = {
                                    CountrySubDivisionCode: customerResponse.shippingAddress.state,
                                    City: customerResponse.shippingAddress.city,
                                    PostalCode: customerResponse.shippingAddress.zip,
                                    Line1: customerResponse.shippingAddress.street1,
                                    Country: "USA"
                                }
                                if(customerResponse.shippingAddress.street2) {
                                    qbCustomer.ShipAddr.Line2 = customerResponse.shippingAddress.street2;
                                }
                            }
                            if(!chimera.deepEqual(customerResponse.accountsPayableEmails, customer.accountsPayableEmails)) {
                                qbCustomer.PrimaryEmailAddr = {
                                    Address: customerResponse.accountsPayableEmails.join(', ')
                                }
                            }
                            if(customerResponse.billingPhone !== customer.billingPhone) {
                                qbCustomer.PrimaryPhone = {
                                    FreeFormNumber: chimera.phoneNumberStr(customerResponse.billingPhone)
                                }
                            }
    
                            if(!chimera.deepEqual(qbCustomer, {})) {
                                try {
                                    await chimera.callQuickBooksAPI(signal, `/api/qb/customer/${customerResponse.integrationIds.quickbooks}`, 'PUT', qbCustomer);
                                }
                                catch(e) {
                                    if(e.name !== "AbortError") {
                                        console.error(e);
                                        const restored = await restoreCustomer(customerResponse._id, 'QuickBooks');
                                        if(restored) {
                                            banners.addBanner('danger', <span><b>QuickBooks Error:</b> Something went wrong and the QuickBooks customer could not be updated. The Chimera customer will not be updated.</span>);
                                        }
                                    }
                                    setIsSaving(false);
                                    setSaveBtnIcon("fas fa-bookmark");
                                    setSaveBtnLabel("Save");
                                    return;
                                }
                            }
                        }
                        catch(e) {
                            console.error(e);
                            banners.addBanner('danger', <span><b>Error:</b> An unhandled error occurred when updating the QuickBooks customer. Neither the QB customer nor the Chimera customer have been updated.</span>);
                            setIsSaving(false);
                            setSaveBtnIcon("fas fa-bookmark");
                            setSaveBtnLabel("Save");
                            return;
                        }
                    }
                    
                    // Create ticket for Pax8 and Duo to be changed manually since API endpoints are not available.
                    if(customerResponse.displayName !== customer.displayName) {
                        let platforms = "";
                        if(customerResponse.integrationIds.pax8 && customerResponse.integrationIds.duo)
                            platforms = "Pax8 and Duo";
                        else if(customerResponse.integrationIds.pax8)
                            platforms = "Pax8";
                        else if(customerResponse.integrationIds.duo)
                            platforms = "Duo";
                        
                        if(platforms !== "") {
                            // TODO: Create Syncro ticket instead of using email.
                            try {
                                await chimera.callAPI(signal, '/api/sendmail', 'POST', {
                                    email: "jaden@gocbit.com",
                                    subject: `Chimera Customer name change: ${customer.displayName} to ${customerResponse.displayName}`,
                                    text: `The Chimera Customer "${customer.displayName}" has had their Display Name changed to "${customerResponse.displayName}". This customer is integrated with ${platforms}, which ${platforms.includes('and') ? "do not" : "does not"} support name changing through their APIs. For consistency and clarity, please manually update these records with the new name.`
                                })
                            }
                            catch(e) {
                                if(e.name !== "AbortError") {
                                    console.error(e);
                                    banners.addBanner('warning', <span><b>Warning:</b> This customer's record in {platforms} cannot be updated due to API limitations, and an email to the manager(s) failed to send. Please notify your IT Manager(s).</span>);
                                }
                            }
                        }
                    }
    
                    // After validation
                    if(customerResponse.taxExempt !== customer.taxExempt && customerResponse.integrationIds.quickbooks) {
                        try {
                            await chimera.callAPI(signal, '/api/sendmail', 'POST', {
                                email: "accounting@gocbit.com",
                                subject: `Chimera Customer Tax Exemption Change for ${customerResponse.displayName}`,
                                text: `The Chimera Customer "${customerResponse.displayName}" has had their Tax Exempt status changed from ${customer.taxExempt} to ${customerResponse.taxExempt}. This change cannot be applied to QuickBooks automatically due to a limitation of their API. Please ensure that this new status is reflected in the corresponding QuickBooks customer. You can access that customer by going to https://app.qbo.intuit.com/app/customerdetail?nameId=${customerResponse.integrationIds.quickbooks}`
                            })
                        }
                        catch(e) {
                            if(e.name !== "AbortController") {
                                console.error(e);
                                banners.addBanner('warning', "Chimera attempted to alert Accounting that the Tax Exemption status changed for this customer, but the email failed to send. Please inform accounting@gocbit.com that the tax exemption status has changed.", "Warning");
                            }
                        }
                    }
                }
    
                // Delete notes in any locations that might have been deleted
                for(let i = 0; i < customer.locations.length; i++) {
                    // look for a match in the customerResponse; no match means it has been deleted. match is done by nickname.
                    let match = false;
                    for(const location of customerResponse.locations) {
                        if(location.nickname === customer.locations[i].nickname) {
                            match = true;
                            break;
                        }
                    }
                    if(!match) {
                        try {
                            await chimera.callAPI(signal, `/api/notes/refId/${customer._id}/subId/${i}`, 'DELETE');
                        }
                        catch(err) {
                            console.error('Failed to delete notes');
                            console.error(err);
                        }
                    }
                }

                // Update IP claims.
                for(const location of customerResponse.locations) {
                    if(location.serviceTypes.internet && ipClaims[location._id] !== undefined) { // if ipClaims[location._id] is undefined, it was unmodified and does no request is needed
                        console.log(`claiming for location ${location.nickname}`);
                        await chimera.callAPI(signal, '/api/iplists/claim', 'POST', {
                            ids: ipClaims[location._id],
                            stamp: {
                                objectType: 'CustomerLocation',
                                objectId: location._id,
                                name: customerResponse.displayName
                            }
                        })
                    }
                }
    
                setCustomer(customerResponse);
                setIsEditing(false);
            }
            catch(e) {
                if(e.name !== "AbortError") {
                    console.error(e);
                    if(e.status === 403) {
                        let problemKey = e.details.problemKey;
                        let cantModify = "";
                        switch(problemKey) {
                            case "businessName":
                                cantModify = "the Business Name";
                                break;
                            case "displayName":
                                cantModify = "the Display Name";
                                break;
                            case "poc":
                                cantModify = "the Point of Contact";
                                break;
                            case "notificationEmails":
                                cantModify = "the Notification Emails";
                                break;
                            case "businessPhone":
                                cantModify = "the Business Phone";
                                break;
                            case "billingAddress":
                                cantModify = "the Billing Address";
                                break;
                            case "serviceAddress":
                                cantModify = "the Service Address";
                                break;
                            case "shippingAddress":
                                cantModify = "the Shipping Address";
                                break;
                            case "serviceTypes":
                                cantModify = "the Service Types";
                                break;
                            case "billingPocs":
                                cantModify = "the Billing Points of Contact";
                                break;
                            case "taxExempt":
                                cantModify = "the Tax Exemption status";
                                break;
                            case "integrationIds":
                                cantModify = "the Integration IDs";
                                break;
                            case "technical":
                                cantModify = "Technical Details";
                                break;
                            case "laborCreditHours":
                                cantModify = "the Labor Credit Hours";
                                break;
                            default:
                                cantModify = "one or more fields";
                                break;
                        }
                        banners.addBanner('danger', <span><b>Forbidden:</b> You do not have permission to modify {cantModify}.</span>);
                    }
                    else if(e.status === 400) {
                        const json = e.details;
                        if(json.name === "ValidationError") {
                            for(const key in json.errors) {
                                const obj = json.errors[key];
                                banners.addBanner('danger', <span><b>Validation Error:</b> {obj.message}</span>);
                            }
                        }
                        else {
                            banners.addBanner('danger', <span><b>Error:</b> An unknown error has occurred. The Customer could not be updated.</span>);
                        }
                    }
                    else {
                        banners.addBanner('danger', <span><b>Error:</b> Failed to update the customer.</span>);
                    }
                }
            }
            setIsSaving(false);
            setSaveBtnIcon("fas fa-bookmark");
            setSaveBtnLabel("Save");
        }

        if(customer.displayName !== workingCustomer.displayName) {
            const choices = [
                {
                    btnColor: 'secondary',
                    btnInner: <span><i className="fas fa-arrow-left"/>&nbsp;Go Back</span>,
                    func: (e) => {
                        e.preventDefault();
                        setIsSaving(false);
                        setSaveBtnIcon("fas fa-bookmark");
                        setSaveBtnLabel("Save");
                        modalContext.setModal(null);
                    }
                },
                {
                    btnColor: 'primary',
                    btnInner: <span><i className="fas fa-floppy-disk"/>&nbsp;Save Former Name</span>,
                    func: (e) => {
                        e.preventDefault();
                        let formerlyKnownAs = [];
                        for(const name of workingCustomer.formerlyKnownAs) {
                            if(!formerlyKnownAs.includes(name)) {
                                formerlyKnownAs.push(name);
                            }
                        }
                        if(!formerlyKnownAs.includes(customer.displayName)) {
                            formerlyKnownAs.push(customer.displayName);
                        }
                        formerlyKnownAs = formerlyKnownAs.filter(name => name !== workingCustomer.displayName);

                        newCustomer.formerlyKnownAs = formerlyKnownAs;
                        save(newCustomer);
                        modalContext.setModal(null);
                    }
                },
                {
                    btnColor: 'primary',
                    btnInner: <span><i className="fas fa-arrow-right"/>&nbsp;Don't Save Former Name; Continue</span>,
                    func: (e) => {
                        e.preventDefault();
                        save(newCustomer);
                        modalContext.setModal(null);
                    }
                }
            ]
            const modal = <Modal choices={choices} dismiss={choices[0].func}>
                <h3>Attention!</h3>
                <h4>Save Former Name?</h4>
                <p>A change to the Display Name has been detected. Do you want to save the old name to the list of Formerly Known As names?</p>
                <p><b>Old Name:</b>&nbsp;{customer.displayName}</p>
                <p><b>New Name:</b>&nbsp;{workingCustomer.displayName}</p>
            </Modal>
            modalContext.setModal(modal);
        }
        else {
            await save(newCustomer);
        }
    }

    const addBillingPoc = event => {
        event.preventDefault();
        let newBillingPocs = [];
        for(const poc of workingCustomer.billingPocs) {
            newBillingPocs.push(poc);
        }
        newBillingPocs.push({
            firstName: "",
            lastName: "",
            email: "",
            phone: ""
        });

        handleChange({
            target: {
                type: "string",
                name: "billingPocs",
                value: newBillingPocs
            },
            preventDefault: () => {}
        })
    }

    const addTechnicalPoc = event => {
        event.preventDefault();
        let newPocs = [];
        for(const poc of workingCustomer.technical.pocs) {
            newPocs.push(poc);
        }
        newPocs.push({
            firstName: "",
            lastName: "",
            email: "",
            phone: ""
        });

        handleChange({
            target: {
                type: "string",
                name: "technical.pocs",
                value: newPocs
            },
            preventDefault: () => {}
        })
    }

    const deleteBillingPoc = index => {
        let newBillingPocs = [];
        for(let i = 0; i < workingCustomer.billingPocs.length; i++) {
            if(i !== index) {
                newBillingPocs.push(workingCustomer.billingPocs[i]);
            }
        }

        handleChange({
            target: {
                type: "string",
                name: "billingPocs",
                value: newBillingPocs
            },
            preventDefault: () => {}
        })
    }

    const deleteTechnicalPoc = index => {
        let newPocs = [];
        for(let i = 0; i < workingCustomer.technical.pocs.length; i++) {
            if(i !== index) {
                newPocs.push(workingCustomer.technical.pocs[i]);
            }
        }

        handleChange({
            target: {
                type: "string",
                name: "technical.pocs",
                value: newPocs
            },
            preventDefault: () => {}
        })
    }

    const addTechString = (to, str) => {
        let newStrings = [];
        for(let i = 0; i < workingCustomer.technical[to].length; i++) {
            newStrings.push(workingCustomer.technical[to][i]);
        }
        newStrings.push(str);

        handleChange({
            target: {
                type: "string",
                name: `technical.${to}`,
                value: newStrings
            },
            preventDefault: () => {}
        })
    }

    const deleteTechString = (from, index) => {
        let newStrings = [];
        for(let i = 0; i < workingCustomer.technical[from].length; i++) {
            if(i !== index) {
                newStrings.push(workingCustomer.technical[from][i]);
            }
        }

        handleChange({
            target: {
                type: "string",
                name: `technical.${from}`,
                value: newStrings
            },
            preventDefault: () => {}
        })
    }

    const trimOnBlur = (event) => {
        handleChange({
            target: {
                type: "string",
                name: event.target.name,
                value: event.target.value.trim()
            },
            preventDefault: () => {}
        })
    }

    const techStringEditing = (field, placeholder) => {
        return (
            <>
            {workingCustomer.technical[field].length > 0 ? 
            <ol className="ps-3 mb-1">
                {workingCustomer.technical[field].map((str, i) => <li key={i}>
                    <div className={i === 0 ? "row" : "row mt-1"}>
                        <div className="col d-flex flex-row">
                            <input className="form-control w-auto" type="text" placeholder={placeholder} name={`technical.${field}[${i}]`} value={str} onChange={handleChange} disabled={isSaving || !hasWritePerm('technical')} onBlur={trimOnBlur}/>
                            <button className="btn btn-sm btn-danger w-auto ms-1" onClick={(event) => {event.preventDefault(); deleteTechString(field, i)}} disabled={isSaving || !hasWritePerm('technical')}>
                                <i className="fas fa-minus"/>
                            </button>
                        </div>
                    </div>
                </li>)}
            </ol>
            :null}
            <button className="btn btn-sm btn-success" onClick={(event) => {event.preventDefault(); addTechString(field, '')}} disabled={isSaving || !hasWritePerm('technical')}>
                <i className="fas fa-plus"/>
            </button>
            </>
        )
    }

    const addNotificationEmail = (event) => {
        event.preventDefault();
        let newEmails = [];
        for(const email of workingCustomer.notificationEmails) {
            newEmails.push(email);
        }
        newEmails.push("");

        handleChange({
            target: {
                type: "string",
                name: "notificationEmails",
                value: newEmails
            },
            preventDefault: () => {}
        })
    }

    const deleteNotificationEmail = (index) => {
        let newEmails = [];
        for(let i = 0; i < workingCustomer.notificationEmails.length; i++) {
            if(i !== index) newEmails.push(workingCustomer.notificationEmails[i]);
        }

        handleChange({
            target: {
                type: "string",
                name: "notificationEmails",
                value: newEmails
            },
            preventDefault: () => {}
        })
    }

    const hasWritePerm = type => {
        return context.permissions[type].write;
    }

    const openPushModal = event => {
        event.preventDefault();
        const choices = [
            {
                btnInner: <span><i className="fas fa-arrow-left"/>&nbsp;Close</span>,
                btnColor: "secondary",
                func: (event) => {
                    event.preventDefault();
                    modalContext.setModal(null);
                }
            }
        ]
        const modal = <Modal choices={choices} dismiss={choices[0].func}>
            <PushSync customers={[customer]}/>
        </Modal>
        modalContext.setModal(modal);
    }

    const standing = () => {
        const tooltip = customer.standing === "good" ? "This customer is in good financial standing with CBIT!" : "This customer has been marked as in bad financial standing with CBIT by a manager.";
        const icon = customer.standing === "good" ? "text-success fas fa-circle-check" : "text-danger fas fa-circle-xmark";
        return (
            <div className="tooltip-container">
                <span className="tooltip-text-bottom fs-5">{tooltip}</span>
                <i className={icon}/>
            </div>
        )
    }

    const setLocation = (newIndex) => {
        customer.locationIndex = newIndex;
        workingCustomer.locationIndex = newIndex;
        reloadComponent(!reloadToggle);
    }

    const openNewLocationModal = (event) => {
        event.preventDefault();
        /**
         * Present form for adding a new location.
         * Add the location.
         * Set the locationIndex to the new index.
         * Close the modal.
         */
        const choices = [
            {
                btnColor: 'secondary',
                btnInner: <span><i className="fas fa-arrow-left"/>&nbsp;Cancel</span>,
                func: (e) => {
                    e.preventDefault();
                    modalContext.backtrack();
                }
            }
        ]
        const handleSubmit = (newLocation) => {
            const newWorkingCustomer = chimera.CommercialCustomer.copy(workingCustomer);
            newWorkingCustomer.locations = [...workingCustomer.locations, newLocation];
            setWorkingCustomer(newWorkingCustomer, workingCustomer.locationIndex);
            banners.addBanner('info', `Created the new location "${newLocation.nickname}" - Be sure to Save!`, 'Success');
            modalContext.backtrack();
        }
        const modal = <Modal choices={choices} dismiss={choices[0].func}>
            <NewLocationForm handleSubmit={handleSubmit}/>
        </Modal>
        modalContext.setModal(modal);
    }

    const confirmDeleteLocation = (index) => {
        /**
         * Present a modal confirmation.
         * If confirmed, delete the location.
         */
        const choices = [
            {
                btnColor: 'secondary',
                btnInner: <span><i className="fas fa-arrow-left"/>&nbsp;Cancel</span>,
                func: (e) => {
                    e.preventDefault();
                    modalContext.backtrack();
                }
            },
            {
                btnColor: 'danger',
                btnInner: <span><i className="fas fa-times"/>&nbsp;Yes, Delete</span>,
                func: (e) => {
                    e.preventDefault();
                    const newWorkingCustomer = chimera.CommercialCustomer.copy(workingCustomer, true);
                    newWorkingCustomer.locations = workingCustomer.locations.filter((_, i) => i !== index);
                    setWorkingCustomer(newWorkingCustomer);
                    banners.addBanner('info', `Deleted the location "${workingCustomer.locations[index].nickname}" - Be sure to Save!`, 'Success');
                    modalContext.backtrack();
                }
            }
        ]
        const modal = <Modal choices={choices} dismiss={choices[0].func}>
            <h3>Delete Location "{workingCustomer.locations[index].nickname}"?</h3>
            <p><strong>Are you sure you want to delete this location? This operation cannot be undone.</strong></p>
        </Modal>
        modalContext.setModal(modal);
    }
    
    const getTagById = (tagId) => {
        for(const tag of tags) {
            if(tag._id === tagId) return tag;
        }
        return null;
    }

    const openIntegrationModal = (event) => {
        event.preventDefault();
        const choices = [
            {
                btnColor: 'secondary',
                btnInner: <span><i className="fas fa-arrow-left"/>&nbsp;Cancel</span>,
                func: (e) => {
                    e.preventDefault();
                    modalContext.backtrack();
                }
            }
        ]
        const callback = (integrationIds) => {
            chimera.callAPI(undefined, `/api/customers/${customer._id}`, 'PUT', {integrationIds: integrationIds})
            .then(newCustomer => setCustomer(new chimera.CommercialCustomer(newCustomer)))
            .catch(err => {
                console.error(err);
                banners.addBanner('danger', 'Failed to save Customer', 'Error');
            })
            .finally(() => {
                modalContext.setModal(null);   
            })
        }
        const modal = <Modal choices={choices} dismiss={choices[0].func}>
            <BannerLog>
                <IntegrationForm customer={customer} callback={callback}/>
            </BannerLog>
        </Modal>
        modalContext.setModal(modal);
    }

    const openEmailModal = async(event) => {
        event.preventDefault();
        const callback = () => {
            const newWorkingCustomer = chimera.CommercialCustomer.copy(workingCustomer);
            newWorkingCustomer.technical.emailSentDate = new Date();

            // PUT
            chimera.callAPI(undefined, `/api/customers/${workingCustomer._id}`, 'PUT', newWorkingCustomer.rawCustomerData)
            .then(savedCustomer => {
                setWorkingCustomer(new chimera.CommercialCustomer(savedCustomer));
            })
            .catch(err => {
                console.error(err);
            });
        }

        let ips = [];
        let subnetMasks = [];
        let gateways = [];

        if(!customer.dhcp && (customer.technical.provider === 'COR' || customer.technical.provider === 'CBIT')) {
            modalContext.setModal(
                <ModalCancelOnly context={modalContext}>
                    <LoadingSpinner size={75}/>
                </ModalCancelOnly>
            )

            const claimedEntries = await chimera.callAPI(undefined, '/api/iplists/getclaims', 'POST', {
                stamp: {
                    objectType: 'CustomerLocation',
                    objectId: customer.locations[customer.locationIndex]._id,
                    name: customer.displayName
                }
            })

            ips = claimedEntries.map(entry => entry.ip);
            subnetMasks = claimedEntries.map(entry => entry.subnetMask);
            gateways = claimedEntries.map(entry => entry.gateway);

            modalContext.backtrack();
        }
        else {
            ips = customer.technical.ips;
            subnetMasks = customer.technical.subnetMasks;
            gateways = customer.technical.gateways;
        }

        /** NOTE: CPEs were removed because they aren't part of the schema. Should they be added? */
        modalContext.setModal(<EmailModal callback={callback} to={customer.poc.email} modalContext={modalContext} backtrack subject={`Your CBIT Fiber Internet Service Details`} text={
            `${customer.displayName},\n\nThank you for choosing CBIT! Your fiber internet service is now available.\n
Please see your service information below: \n
Equipment Location:\n${customer.technical.equipmentLocation}\n
Service Port:\n${customer.technical.servicePort}\n
CID(s):\n${customer.technical.cids.join('\n')}\n
IP(s):\n${ips.join('\n')}\n
Subnet Mask(s):\n${subnetMasks.join('\n')}\n
Gateway(s):\n${gateways.join('\n')}\n
If you have any questions or issues please reach out and we will be happy to help you!\n
Business Hours:
Monday-Friday
7am-4:30pm

Tech Support:
support@gocbit.com
(509) 578-5424 option 2

Billing:
accounting@gocbit.com
(509) 578-5424 option 3`
        }/>);
    }

    const addSoftwareVendor = (e) => {
        e.preventDefault();
        handleChange({
            target: {
                type: "array",
                name: 'technical.softwareVendors',
                value: [...workingCustomer.technical.softwareVendors, {title: '', notes: ''}],
            },
            preventDefault: () => {}
        })
    }

    const removeSoftwareVendorAtIndex = (index) => {
        handleChange({
            target: {
                type: "array",
                name: 'technical.softwareVendors',
                value: workingCustomer.technical.softwareVendors.filter((_, i) => i !== index),
            },
            preventDefault: () => {}
        })
    }

    const addISP = (e) => {
        e.preventDefault();
        handleChange({
            target: {
                type: "array",
                name: 'technical.general.isps',
                value: [...workingCustomer.technical.general.isps, {isp: '', ispSupportEmail: '', ispSupportNumber: '', wanIp: '', wanGateway: '', wanSubnet: ''}],
            },
            preventDefault: () => {}
        })
    }

    const removeISPAtIndex = (index) => {
        handleChange({
            target: {
                type: "array",
                name: 'technical.general.isps',
                value: workingCustomer.technical.general.isps.filter((_, i) => i !== index),
            },
            preventDefault: () => {}
        })
    }

    const openSetActiveModal = (event) => {
        const onClose = () => {
            setCustomer(null);
        }
        modalContext.setModal(<SetActiveModal customers={[customer]} modalContext={modalContext} onClose={onClose} activeDefault={customer.active ? 'Inactive' : 'Active'}/>);
    }

    return (
        <>
            {workingCustomer && customer ? 
                <>
                <div className="row">
                    <div className="col-2 px-0 d-flex align-items-center justify-content-start">
                        <NavLink className="btn btn-secondary w-auto" to="/customers">
                            <i className="fas fa-arrow-left"/>
                        </NavLink>
                    </div>
                    <div className="col-8">
                        {!isEditing ? 
                            <>
                            {!customer.active ? 
                                <div className="alert alert-danger w-fit mx-auto">
                                    (Inactive)
                                </div>
                            :null}
                            <h1 className="my-0">{customer.displayName}&nbsp;{standing()}</h1>
                            <h2 className="fs-4 my-0">({customer.businessName})</h2>
                            <span className="fs-5">
                                # {customer.accountNumber}
                            </span>
                            </>
                        :
                            <>
                            <div className="row mb-1 mt-2">
                                <div className="col">
                                    <input className="form-control form-control-lg" type="text" name="displayName" placeholder="Display Name" value={workingCustomer.displayName} onChange={handleChange} onBlur={trimOnBlur}/>
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col">
                                    <input className="form-control form-control-lg" type="text" name="businessName" placeholder="Business Name" value={workingCustomer.businessName} onChange={handleChange} onBlur={trimOnBlur}/>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col">
                                    <div className="form-check text-start">
                                        <input className="form-check-input" type="checkbox" name="standing" id="standing" checked={workingCustomer.standing === "good"} onChange={handleChange} disabled={isSaving || !hasWritePerm('financial')}/>
                                        <label className="form-check-label" htmlFor="standing">
                                            In Good Standing?
                                        </label>
                                    </div>
                                </div>
                            </div>
                            </>
                        }
                    </div>
                    {!isEditing ?
                    <div className="col-2 px-0 d-flex align-items-center justify-content-end">
                        <div className="dropdown">
                            <button className="btn btn-secondary dropdown-toggle" type="button" id="actionsBtn" data-bs-toggle="dropdown" aria-expanded="false">
                                Actions
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="actionsBtn">
                                <li>
                                    <button className="btn dropdown-item" onClick={startEditing}>
                                        Edit
                                    </button>
                                </li>
                                <li>
                                    <button className="btn dropdown-item" onClick={openPushModal}>
                                        Push
                                    </button>
                                </li>
                                <li>
                                    <button className="btn dropdown-item" onClick={openIntegrationModal}>
                                        Integrate...
                                    </button>
                                </li>
                                <li>
                                    <button className="btn text-danger dropdown-item" onClick={openSetActiveModal}>
                                        {customer.active ? 'Make Inactive' : 'Make Active'}
                                    </button>
                                </li>
                                <li>
                                    <button className="btn text-danger dropdown-item" onClick={confirmDelete}>
                                        Delete
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    :
                    <div className="col-2 px-0">
                        <div className="row h-100">
                            <div className="col h-100 d-flex flex-row align-items-center justify-content-end">
                                <button className="btn btn-secondary me-2 w-auto" onClick={cancelEditing} disabled={isSaving}>
                                    <i className="fas fa-arrow-left"/>&nbsp;Cancel
                                </button>
                                <button className="btn btn-warning w-auto" onClick={saveChanges} disabled={isSaving}>
                                    <i className={saveBtnIcon}/>&nbsp;{saveBtnLabel}
                                </button>
                            </div>
                        </div>
                    </div>
                    }
                </div>
                {isEditing || customer.locations.length > 1 ? 
                <div className="row">
                    <div className="col p-0">
                        <ul className="nav nav-tabs mb-3">
                            {workingCustomer.locations.map((location, i) => <li key={i} className="nav-item">
                                <a href="#" className={workingCustomer.locationIndex === i ? 'btn nav-link active d-flex flex-row' : 'btn nav-link d-flex flex-row'} onClick={(event) => {event.preventDefault(); setLocation(i)}}>
                                    {isEditing && workingCustomer.locations.length > 1 ? <><button className="btn btn-sm btn-danger" onClick={(event) => {event.preventDefault(); confirmDeleteLocation(i);}}><i className="fas fa-times"/></button>&nbsp;</> : null}
                                    {isEditing ? 
                                        <input style={{width: '7rem'}}  type="text" className="form-control" name={`locations[${i}].nickname`} placeholder={`${i+1}`} value={workingCustomer.locations[i].nickname} onChange={handleChange} disabled={isSaving || !hasWritePerm('managerial')} onBlur={trimOnBlur}/>
                                    :location.nickname}
                                </a>
                            </li>)}
                            {isEditing ? 
                                <li className="nav-item">
                                    <button className="btn nav-link btn-success" onClick={openNewLocationModal}>
                                        <i className="fas fa-plus text-success"/>
                                    </button>
                                </li>
                            :null}
                        </ul>
                    </div>
                </div>
                :null}
                <div className="row">
                    <div className="col-lg-3">
                        <div className="row">
                            <div className="section-outline">
                                <h5><b>Point of Contact</b></h5>
                                <PocDisplay poc={customer.poc} isEditing={isEditing} basePath="poc" baseValue={workingCustomer} onChange={handleChange} disabled={isSaving || !hasWritePerm('managerial')} onBlur={trimOnBlur}/>
                            </div>
                        </div>
                        <div className="row mt-1">
                            <div className="section-outline">
                                <h5><b>Business Phone</b></h5>
                                {!isEditing ? 
                                <div className="row text-start">
                                    <span><i className="fas fa-phone"/>&nbsp; {chimera.phoneNumberStr(customer.businessPhone)}</span>
                                </div>
                                :
                                <div className="row">
                                    <div className="col">
                                        <input type="text" className="form-control" name="businessPhone" placeholder="15551234567" value={chimera.getAttr(workingCustomer, 'businessPhone')} onChange={handleChange} maxLength={11} disabled={isSaving || !hasWritePerm('managerial')} onBlur={trimOnBlur}/>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                        <div className="row mt-1">
                            <div className="section-outline">
                                <h5><b>Notification Emails</b></h5>
                                {!isEditing ? 
                                    <>
                                    {customer.notificationEmails.map((email, i) => <div key={i} className="row text-start">
                                        <div className="col">
                                            <i className="fas fa-envelope"/>&nbsp;{email}
                                        </div>
                                    </div>)}
                                    </>
                                :
                                    <>
                                    {workingCustomer.notificationEmails.map((email, i) => <div key={i} className="row text-start mb-1">
                                        <div className="col d-flex flex-row align-items-center">
                                            <i className="fas fa-envelope"/>&nbsp;<input className="form-control w-auto me-1" type="text" placeholder="Email" name={`notificationEmails[${i}]`} value={email} onChange={handleChange} disabled={isSaving || !hasWritePerm('managerial')} onBlur={trimOnBlur}/>
                                            <button className="btn btn-sm btn-danger" onClick={(event) => {event.preventDefault(); deleteNotificationEmail(i)}} disabled={isSaving || !hasWritePerm('managerial')}>
                                                <i className="fas fa-minus"/>
                                            </button>
                                        </div>
                                    </div>)}
                                    <div className="row">
                                        <div className="col d-flex align-items-center justify-content-start">
                                            <button className="btn btn-sm btn-success" onClick={addNotificationEmail} disabled={isSaving || !hasWritePerm('managerial')}>
                                                <i className="fas fa-plus"/>
                                            </button>
                                        </div>
                                    </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="section-outline">
                            <div className="row">
                                <div className="col">
                                    <h5><b>Service Address</b></h5>
                                    {isEditing ? <i className="text-info"><i className="fas fa-circle-info"/>&nbsp;Please find the Billing Address below.</i> : null}
                                    <AddressDisplay addr={customer.serviceAddress} isEditing={isEditing} basePath="serviceAddress" baseValue={workingCustomer} onChange={handleChange} disabled={isSaving || !hasWritePerm('managerial')} onBlur={trimOnBlur}/>
                                </div>
                                <div className="col">
                                    <h5><b>Shipping Address</b></h5>
                                    {isEditing ? <i className="text-info"><i className="fas fa-circle-info"/>&nbsp;Please find the Billing Address below.</i> : null}
                                    <AddressDisplay addr={customer.shippingAddress} isEditing={isEditing} basePath="shippingAddress" baseValue={workingCustomer} onChange={handleChange} disabled={isSaving || !hasWritePerm('managerial')} onBlur={trimOnBlur}/>
                                </div>
                            </div>
                        </div>
                        <div className="section-outline mt-1">
                            <div className="row">
                                <div className="col">
                                    <NotesSection header="Notes" setNotes={setNotes} notes={notes} technical={false} refId={customer._id} subId={customer.locationIndex.toString()}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="row">
                            <div className="section-outline">
                                <h5><b>Service Types</b></h5>
                                {!isEditing ? 
                                <b>
                                    Internet:&nbsp;<i className={`fas fa-${customer.serviceTypes.internet ? 'check' : 'ban'}`}/>&nbsp;
                                    VoIP:&nbsp;<i className={`fas fa-${customer.serviceTypes.voip ? 'check' : 'ban'}`}/>&nbsp;
                                    MSP:&nbsp;<i className={`fas fa-${customer.serviceTypes.msp ? 'check' : 'ban'}`}/>&nbsp;
                                    Video:&nbsp;<i className={`fas fa-${customer.serviceTypes.video ? 'check' : 'ban'}`}/>
                                </b>
                                :
                                <div className="text-start">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="serviceTypes.internet" id="internetService" checked={workingCustomer.serviceTypes.internet} onChange={handleChange} disabled={isSaving || !hasWritePerm('managerial')}/>
                                        <label className="form-check-label" htmlFor="internetService">
                                            Internet
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="serviceTypes.voip" id="voipService" checked={workingCustomer.serviceTypes.voip} onChange={handleChange} disabled={isSaving || !hasWritePerm('managerial')}/>
                                        <label className="form-check-label" htmlFor="voipService">
                                            VoIP
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="serviceTypes.msp" id="mspService" checked={workingCustomer.serviceTypes.msp} onChange={handleChange} disabled={isSaving || !hasWritePerm('managerial')}/>
                                        <label className="form-check-label" htmlFor="mspService">
                                            MSP
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" name="serviceTypes.video" id="videoService" checked={workingCustomer.serviceTypes.video} onChange={handleChange} disabled={isSaving || !hasWritePerm('managerial')}/>
                                        <label className="form-check-label" htmlFor="videoService">
                                            Video
                                        </label>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                        <div className="row mt-1">
                            <div className="section-outline">
                                <h5><b>On-Call / After Hours</b></h5>
                                {!isEditing ? 
                                <span>{customer.onCallAfterHours}</span>
                                :
                                <div className="row">
                                    <div className="col">
                                        <select className="form-select w-100" name="onCallAfterHours" value={workingCustomer.onCallAfterHours} onChange={handleChange} disabled={isSaving || !hasWritePerm("managerial")}>
                                            <option value="Business Hours Only">Business Hours Only</option>
                                            <option value="Extended Weekdays">Extended Weekdays</option>
                                            <option value="Extended Weekdays + Weekends">Extended Weekdays + Weekends</option>
                                        </select>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-1">
                    <div className="section-outline">
                        <h5><b>Integrations</b></h5>
                        <IntegrationsDisplay customer={customer} isEditing={isEditing} baseValue={workingCustomer} basePath={"integrationIds"} onChange={handleChange} disabled={isSaving || !hasWritePerm('managerial')} onBlur={trimOnBlur}/>
                    </div>
                </div>
                {context.permissions.financial.read ?
                <div className="row mt-1">
                    <div className="section-outline">
                        <h5><b>Financial</b></h5>
                        <div className="row">
                            <div className="col-6">
                                <div className="row mb-1">
                                    <div className="col-3 text-end">
                                        <b>Billing POCs:</b>
                                    </div>
                                    <div className="col-9">
                                        {isEditing ? 
                                            <>
                                            {workingCustomer.billingPocs.map((poc, i) => <div key={i} className={i === workingCustomer.billingPocs.length - 1 ? "row mb-1" : "row mb-4"}><div className="col d-flex justify-content-start"><div className="section-outline me-1 w-fit"><PocDisplay poc={poc} isEditing={isEditing} basePath={`billingPocs[${i}]`} baseValue={workingCustomer} onChange={handleChange} handleDelete={(event) => {event.preventDefault(); deleteBillingPoc(i)}} series disabled={isSaving || !hasWritePerm('financial')} onBlur={trimOnBlur}/></div></div></div>)}
                                            <div className="row">
                                                <div className="col d-flex justify-content-start">
                                                    <button className="btn btn-success" onClick={addBillingPoc} disabled={isSaving || !hasWritePerm('financial')}>
                                                        <i className="fas fa-plus"/>
                                                    </button>
                                                </div>
                                            </div>
                                        </>
                                        :
                                            <>{customer.billingPocs.map((poc, i) => <div key={i} className={i === 0 ? "row" : "row mt-1"}><div className="col d-flex justify-content-start"><div className="section-outline me-1 w-fit"><PocDisplay poc={poc} series disabled={isSaving || !hasWritePerm('financial')} onBlur={trimOnBlur}/></div></div></div>)}</>
                                        }
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-3 text-end">
                                        <b>Billing Address:</b>
                                    </div>
                                    <div className="col-9">
                                        <div className="section-outline w-fit"><AddressDisplay addr={customer.billingAddress} isEditing={isEditing} basePath="billingAddress" baseValue={workingCustomer} onChange={handleChange} disabled={isSaving || !hasWritePerm('financial')} onBlur={trimOnBlur}/></div>
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-3 text-end">
                                        <b>Billing Phone:</b>
                                    </div>
                                    <div className="col-9 text-start">
                                        {!isEditing ? 
                                            <span>{chimera.phoneNumberStr(customer.billingPhone)}</span>
                                        :
                                            <input className="form-control w-auto" name="billingPhone" type="text" maxLength={11} value={workingCustomer.billingPhone} onChange={handleChange} disabled={isSaving || !hasWritePerm('financial')} onBlur={trimOnBlur}/>
                                        }
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-3 text-end">
                                        <b>Accounts Payable Emails:</b>
                                    </div>
                                    <div className="col-9 text-start">
                                        {!isEditing ? 
                                            <span>{customer.accountsPayableEmails.join(', ')}</span>
                                        :
                                            <StringList obj={workingCustomer} basePath="accountsPayableEmails" handleChange={handleChange} placeholder="accountspayable@example.com"/>
                                        }
                                    </div>
                                </div>
                                <div className="row mb-1 text-start">
                                    <div className="col-3 text-end">
                                        <b>Standard Labor Rate:</b>
                                    </div>
                                    <div className="col-9 text-start">
                                        {!isEditing ? 
                                            <span>{chimera.dollarStr(customer.standardRate)}</span>
                                        :
                                            <input className="form-control w-auto" name="standardRate" type="number" min="0" step="5" value={workingCustomer.standardRate} onChange={handleChange} disabled={isSaving || !hasWritePerm('financial')}/>
                                        }
                                    </div>
                                </div>
                                <div className="row mb-1 text-start">
                                    <div className="col-3 text-end">
                                        <b>After-Hours Labor Rate:</b>
                                    </div>
                                    <div className="col-9 text-start">
                                        {!isEditing ? 
                                            <span>{chimera.dollarStr(customer.afterHoursRate)}</span>
                                        :
                                            <input className="form-control w-auto" name="afterHoursRate" type="number" min="0" step="5" value={workingCustomer.afterHoursRate} onChange={handleChange} disabled={isSaving || !hasWritePerm('financial')}/>
                                        }
                                    </div>
                                </div>
                                <div className="row mb-1 text-start">
                                    <div className="col-3 text-end">
                                        <b>Labor Credit Hours:</b>
                                    </div>
                                    <div className="col-9 text-start">
                                        {!isEditing ? 
                                            <span>{customer.laborCreditHours.toFixed(2)}</span>
                                        :
                                            <input className="form-control w-auto" name="laborCreditHours" type="number" min="0" step="0.25" value={workingCustomer.laborCreditHours} onChange={handleChange} disabled={isSaving || !hasWritePerm('financial')}/>
                                        }
                                    </div>
                                </div>
                                <div className="row text-start">
                                    <div className="col-3 text-end">
                                        <b>Tax Exempt:</b>
                                    </div>
                                    <div className="col-9 text-start">
                                        {!isEditing ? 
                                            <i className={customer.taxExempt ? 'fas fa-check' : 'fas fa-ban'}/>
                                        :
                                            <input className="form-check-input" name="taxExempt" type="checkbox" checked={workingCustomer.taxExempt} onChange={handleChange} disabled={isSaving || !hasWritePerm('financial')}/>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                {customer.integrationIds.quickbooks && !isEditing ? 
                                    <>
                                    <div className="row mb-1">
                                        {!chimera.isDeveloperMode() ? <OpenBalancePastDue id={customer.integrationIds.quickbooks}/> : <p className="text-muted">(Disabled in Developer Mode)</p>}
                                    </div>
                                    <div className="row mb-1">
                                        {!chimera.isDeveloperMode() ? 
                                        <div className="col d-flex flex-column">
                                            <b className="text-start">Unsent Invoices:</b>
                                            <UnsentInvoices id={customer.integrationIds.quickbooks}/>
                                        </div>
                                        :
                                        <p className="text-muted">(Disabled in Developer Mode)</p>}
                                    </div>
                                    </>
                                :null}
                            </div>
                        </div>
                    </div>
                </div>
                : null}
                {context.permissions.technical.read ? 
                <>
                    <div className="row mt-1 section-outline">
                        <h4><b>MSP Information</b></h4>
                        <h5><b>General</b></h5>
                        <div className="row mx-auto">
                            <div className="col-6">
                            <div className="row mt-1">
                                    <div className="col-3 text-end">
                                        <b>Email Provider:</b>
                                    </div>
                                    <div className="col-9 text-start">
                                        {!isEditing ? 
                                            <p>
                                                {customer.technical.general.emailProvider ? customer.technical.general.emailProvider : "(NOT SET)"}
                                            </p>
                                        :
                                            <ExpandableDropdown listName="CUSTOMER_EMAIL_PROVIDER" htmlName="technical.general.emailProvider" value={workingCustomer.technical.general.emailProvider} handleChange={handleChange} disabled={isSaving} banners={banners} modalContext={modalContext}/>
                                        }
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-3 text-end">
                                        <b>Cloud/Hosting Provider:</b>
                                    </div>
                                    <div className="col-9 text-start">
                                        {!isEditing ? 
                                            <p>
                                                {customer.technical.general.cloudHostingProvider ? customer.technical.general.cloudHostingProvider : "(NOT SET)"}
                                            </p>
                                        :
                                            <ExpandableDropdown listName="CUSTOMER_CLOUD_HOSTING_PROVIDER" htmlName="technical.general.cloudHostingProvider" value={workingCustomer.technical.general.cloudHostingProvider} handleChange={handleChange} disabled={isSaving} banners={banners} modalContext={modalContext}/>
                                        }
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-3 text-end">
                                        <b>VoIP Provider:</b>
                                    </div>
                                    <div className="col-9 text-start">
                                        {!isEditing ? 
                                            <p>
                                                {customer.technical.general.voipProvider ? customer.technical.general.voipProvider : "(NOT SET)"}
                                            </p>
                                        :
                                            <ExpandableDropdown listName="CUSTOMER_VOIP_PROVIDER" htmlName="technical.general.voipProvider" value={workingCustomer.technical.general.voipProvider} handleChange={handleChange} disabled={isSaving} banners={banners} modalContext={modalContext}/>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="row mt-1">
                                    <div className="col-3 text-end">
                                        <b>Registrar:</b>
                                    </div>
                                    <div className="col-9 text-start">
                                        {!isEditing ? 
                                            <p>
                                                {customer.technical.general.registrar ? customer.technical.general.registrar : "(NOT SET)"}
                                            </p>
                                        :
                                            <ExpandableDropdown listName="CUSTOMER_REGISTRAR" htmlName="technical.general.registrar" value={workingCustomer.technical.general.registrar} handleChange={handleChange} disabled={isSaving} banners={banners} modalContext={modalContext}/>
                                        }
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-3 text-end">
                                        <b>Domain Name:</b>
                                    </div>
                                    <div className="col-9 text-start">
                                        {!isEditing ? 
                                            <p>
                                                {customer.technical.general.domainName ? customer.technical.general.domainName : "(None)"}
                                            </p>
                                        :
                                            <input className="form-control" type="text" name="technical.general.domainName" value={workingCustomer.technical.general.domainName} onChange={handleChange} onBlur={trimOnBlur} disabled={isSaving}/>
                                        }
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-3 text-end">
                                        <b>Dental?:</b>
                                    </div>
                                    <div className="col-9 text-start">
                                        {!isEditing ? 
                                            <p>
                                                {customer.technical.general.dental ? 'Yes' : 'No'}
                                            </p>
                                        :
                                        <div className="row">
                                            <div className="col">
                                                <select className="form-select w-100" name="technical.general.dental" value={workingCustomer.technical.general.dental} onChange={handleChange} disabled={isSaving}>
                                                    <option value={true}>Yes</option>
                                                    <option value={false}>No</option>
                                                </select>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {workingCustomer.technical.general.dental ? 
                        <>
                        <h5><b>Dental</b></h5>
                        <div className="row mx-auto">
                            <div className="col-6">
                                <div className="row mt-1">
                                    <div className="col-3 text-end">
                                        <b>PMS:</b>
                                    </div>
                                    <div className="col-9 text-start">
                                        {!isEditing ? 
                                            <p>
                                                {customer.technical.dental.pms ? customer.technical.dental.pms : "(NOT SET)"}
                                            </p>
                                        :
                                            <ExpandableDropdown listName="CUSTOMER_PMS" htmlName="technical.dental.pms" value={workingCustomer.technical.dental.pms} handleChange={handleChange} disabled={isSaving} banners={banners} modalContext={modalContext}/>
                                        }
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-3 text-end">
                                        <b>PMS Support #:</b>
                                    </div>
                                    <div className="col-9 text-start">
                                        {!isEditing ? 
                                            <p>
                                                {customer.technical.dental.pmsSupportNumber ? customer.technical.dental.pmsSupportNumber : "(None)"}
                                            </p>
                                        :
                                            <input className="form-control" type="text" name="technical.dental.pmsSupportNumber" value={workingCustomer.technical.dental.pmsSupportNumber} onChange={handleChange} onBlur={trimOnBlur}/>
                                        }
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-3 text-end">
                                        <b>PMS Account #:</b>
                                    </div>
                                    <div className="col-9 text-start">
                                        {!isEditing ? 
                                            <p>
                                                {customer.technical.dental.pmsAccountNumber ? customer.technical.dental.pmsAccountNumber : "(None)"}
                                            </p>
                                        :
                                            <input className="form-control" type="text" name="technical.dental.pmsAccountNumber" value={workingCustomer.technical.dental.pmsAccountNumber} onChange={handleChange} onBlur={trimOnBlur}/>
                                        }
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-3 text-end">
                                        <b>2D XRAY Vendor:</b>
                                    </div>
                                    <div className="col-9 text-start">
                                        {!isEditing ? 
                                            <p>
                                                {customer.technical.dental.xrayVendor2D ? customer.technical.dental.xrayVendor2D : "(NOT SET)"}
                                            </p>
                                        :
                                            <ExpandableDropdown listName="CUSTOMER_2D_XRAY_VENDOR" htmlName="technical.dental.xrayVendor2D" value={workingCustomer.technical.dental.xrayVendor2D} handleChange={handleChange} disabled={isSaving} banners={banners} modalContext={modalContext}/>
                                        }
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-3 text-end">
                                        <b>2D XRAY Vendor Support #:</b>
                                    </div>
                                    <div className="col-9 text-start">
                                        {!isEditing ? 
                                            <p>
                                                {customer.technical.dental.xrayVendor2DSupportNumber ? customer.technical.dental.xrayVendor2DSupportNumber : "(None)"}
                                            </p>
                                        :
                                            <input className="form-control" type="text" name="technical.dental.xrayVendor2DSupportNumber" value={workingCustomer.technical.dental.xrayVendor2DSupportNumber} onChange={handleChange} onBlur={trimOnBlur}/>
                                        }
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-3 text-end">
                                        <b>2D XRAY Vendor Account #:</b>
                                    </div>
                                    <div className="col-9 text-start">
                                        {!isEditing ? 
                                            <p>
                                                {customer.technical.dental.xrayVendor2DAccountNumber ? customer.technical.dental.xrayVendor2DAccountNumber : "(None)"}
                                            </p>
                                        :
                                            <input className="form-control" type="text" name="technical.dental.xrayVendor2DAccountNumber" value={workingCustomer.technical.dental.xrayVendor2DAccountNumber} onChange={handleChange} onBlur={trimOnBlur}/>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="row mt-1">
                                    <div className="col-3 text-end">
                                        <b>3D XRAY Vendor:</b>
                                    </div>
                                    <div className="col-9 text-start">
                                        {!isEditing ? 
                                            <p>
                                                {customer.technical.dental.xrayVendor3D ? customer.technical.dental.xrayVendor3D : "(NOT SET)"}
                                            </p>
                                        :
                                            <ExpandableDropdown listName="CUSTOMER_3D_XRAY_VENDOR" htmlName="technical.dental.xrayVendor3D" value={workingCustomer.technical.dental.xrayVendor3D} handleChange={handleChange} disabled={isSaving} banners={banners} modalContext={modalContext}/>
                                        }
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-3 text-end">
                                        <b>3D XRAY Vendor Support #:</b>
                                    </div>
                                    <div className="col-9 text-start">
                                        {!isEditing ? 
                                            <p>
                                                {customer.technical.dental.xrayVendor3DSupportNumber ? customer.technical.dental.xrayVendor3DSupportNumber : "(None)"}
                                            </p>
                                        :
                                            <input className="form-control" type="text" name="technical.dental.xrayVendor3DSupportNumber" value={workingCustomer.technical.dental.xrayVendor3DSupportNumber} onChange={handleChange} onBlur={trimOnBlur}/>
                                        }
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-3 text-end">
                                        <b>3D XRAY Vendor Account #:</b>
                                    </div>
                                    <div className="col-9 text-start">
                                        {!isEditing ? 
                                            <p>
                                                {customer.technical.dental.xrayVendor3DAccountNumber ? customer.technical.dental.xrayVendor3DAccountNumber : "(None)"}
                                            </p>
                                        :
                                            <input className="form-control" type="text" name="technical.dental.xrayVendor3DAccountNumber" value={workingCustomer.technical.dental.xrayVendor3DAccountNumber} onChange={handleChange} onBlur={trimOnBlur}/>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        </>
                        :null}
                        <div className="row my-2 mx-auto section-outline">
                            <h5><b>Internet Provider(s)</b></h5>
                            {workingCustomer.technical.general.isps.length > 0 ?
                            <>
                            {workingCustomer.technical.general.isps.map((isp, i) => <div className="row section-outline mx-auto mb-1" key={i}>
                                <div className="col-6">
                                    <div className="row mt-1">
                                        <div className="col-3 text-end">
                                            <b>ISP:</b>
                                        </div>
                                        <div className="col-9 text-start">
                                            {!isEditing ? 
                                                <p>
                                                    {isp.isp ? isp.isp : "(NOT SET)"}
                                                </p>
                                            :
                                                <ExpandableDropdown listName="CUSTOMER_ISP" htmlName={`technical.general.isps[${i}].isp`} value={isp.isp} handleChange={handleChange} disabled={isSaving} banners={banners} modalContext={modalContext}/>
                                            }
                                        </div>
                                    </div>
                                    <div className="row mt-1">
                                        <div className="col-3 text-end">
                                            <b>ISP Support #:</b>
                                        </div>
                                        <div className="col-9 text-start">
                                            {!isEditing ? 
                                                <p>
                                                    {isp.ispSupportNumber ? isp.ispSupportNumber : "(None)"}
                                                </p>
                                            :
                                                <input className="form-control" type="text" name={`technical.general.isps[${i}].ispSupportNumber`} value={isp.ispSupportNumber} onChange={handleChange} onBlur={trimOnBlur}/>
                                            }
                                        </div>
                                    </div>
                                    <div className="row mt-1">
                                        <div className="col-3 text-end">
                                            <b>ISP Support Email:</b>
                                        </div>
                                        <div className="col-9 text-start">
                                            {!isEditing ? 
                                                <p>
                                                    {isp.ispSupportEmail ? isp.ispSupportEmail : "(None)"}
                                                </p>
                                            :
                                                <input className="form-control" type="text" name={`technical.general.isps[${i}].ispSupportEmail`} value={isp.ispSupportEmail} onChange={handleChange} onBlur={trimOnBlur}/>
                                            }
                                        </div>
                                    </div>
                                    <div className="row mt-1">
                                        <div className="col-3 text-end">
                                            <b>CID:</b>
                                        </div>
                                        <div className="col-9 text-start">
                                            {!isEditing ? 
                                                <>
                                                {isp.cid ? <code>{isp.cid}</code> : <p>(None)</p>}
                                                </>
                                            :
                                                <input className="form-control" type="text" name={`technical.general.isps[${i}].cid`} value={isp.cid} onChange={handleChange} onBlur={trimOnBlur}/>
                                            }
                                        </div>
                                    </div>
                                    <div className="row mt-1">
                                        <div className="col-3 text-end">
                                            <b>Equipment Location:</b>
                                        </div>
                                        <div className="col-9 text-start">
                                            {!isEditing ? 
                                                <>
                                                {isp.equipmentLocation !== "" ? 
                                                    <>{isp.equipmentLocation.split('\n').map((parag, i) => <p key={i}>{parag}</p>)}</>
                                                :
                                                    <p>(None)</p>
                                                }
                                                </>
                                            :
                                                <textarea className="form-control" placeholder="Location" name={`technical.general.isps[${i}].equipmentLocation`} value={isp.equipmentLocation} onChange={handleChange} disabled={isSaving || !hasWritePerm("technical")} onBlur={trimOnBlur}/>
                                            }
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-3 text-end">
                                            <b>Service Port:</b>
                                        </div>
                                        <div className="col-9 text-start">
                                            {!isEditing ? 
                                                <>
                                                {isp.servicePort !== "" ? 
                                                    <>{isp.servicePort.split('\n').map((parag, i) => <p key={i}>{parag}</p>)}</>
                                                :
                                                    <p>(None)</p>
                                                }
                                                </>
                                            :
                                                <textarea className="form-control" placeholder="Service Port" name={`technical.general.isps[${i}].servicePort`} value={isp.servicePort} onChange={handleChange} disabled={isSaving || !hasWritePerm("technical")} onBlur={trimOnBlur}/>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="row mt-1">
                                    <div className="col-3 text-end">
                                            <b>WAN IP:</b>
                                        </div>
                                        <div className="col-9 text-start">
                                            {!isEditing ? 
                                                <p>
                                                    {isp.wanIp ? isp.wanIp : "(None)"}
                                                </p>
                                            :
                                                <input className="form-control" type="text" name={`technical.general.isps[${i}].wanIp`} value={isp.wanIp} onChange={handleChange} onBlur={trimOnBlur}/>
                                            }
                                        </div>
                                    </div>
                                    <div className="row mt-1">
                                        <div className="col-3 text-end">
                                            <b>WAN Subnet:</b>
                                        </div>
                                        <div className="col-9 text-start">
                                            {!isEditing ? 
                                                <p>
                                                    {isp.wanSubnet ? isp.wanSubnet : "(None)"}
                                                </p>
                                            :
                                                <input className="form-control" type="text" name={`technical.general.isps[${i}].wanSubnet`} value={isp.wanSubnet} onChange={handleChange} onBlur={trimOnBlur}/>
                                            }
                                        </div>
                                    </div>
                                    <div className="row mt-1">
                                        <div className="col-3 text-end">
                                            <b>WAN Gateway:</b>
                                        </div>
                                        <div className="col-9 text-start">
                                            {!isEditing ? 
                                                <p>
                                                    {isp.wanGateway ? isp.wanGateway : "(None)"}
                                                </p>
                                            :
                                                <input className="form-control" type="text" name={`technical.general.isps[${i}].wanGateway`} value={isp.wanGateway} onChange={handleChange} onBlur={trimOnBlur}/>
                                            }
                                        </div>
                                    </div>
                                    <div className="row mt-1">
                                        <div className="col-3 text-end">
                                            <b>Service:</b>
                                        </div>
                                        <div className="col-9 text-start">
                                            {!isEditing ? 
                                                <p>{isp.service === "NOT SET" ? "(Not Set)" : isp.service}</p>
                                            :
                                            <div className="row">
                                                <div className="col">
                                                    <select className="form-select w-100" name={`technical.general.isps[${i}].service`} value={isp.service} onChange={handleChange} disabled={isSaving}>
                                                        <option value="NOT SET">-- None Selected --</option>
                                                        <option value="100 Mbps">100 Mbps</option>
                                                        <option value="200 Mbps">200 Mbps</option>
                                                        <option value="250 Mbps">250 Mbps</option>
                                                        <option value="500 Mbps">500 Mbps</option>
                                                        <option value="1 Gbps">1 Gbps</option>
                                                        <option value="2 Gbps">2 Gbps</option>
                                                        <option value="30 Gbps">30 Gbps</option>
                                                    </select>
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="row mt-1">
                                        <div className="col-3 text-end">
                                            <b>DHCP?:</b>
                                        </div>
                                        <div className="col-9 text-start">
                                            {!isEditing ? 
                                            <p>{isp.dhcp ? 'True' : 'False'}</p>   
                                            :
                                            <input className="form-check-input" name={`technical.general.isps[${i}].dhcp`} type="checkbox" checked={isp.dhcp} onChange={handleChange} disabled={isSaving || !hasWritePerm('technical')}/>
                                            }
                                        </div>
                                    </div>
                                    {isEditing ?
                                    <div className="row mt-1">
                                        <div className="col">
                                            <button className="btn btn-danger float-end" onClick={(e) => {e.preventDefault(); removeISPAtIndex(i)}} disabled={isSaving}>
                                                <i className="fas fa-times"/>&nbsp;Remove ISP
                                            </button>
                                        </div>
                                    </div>
                                    :null}
                                </div>
                            </div>)}
                            </>
                            :
                            <span>(None)</span>
                            }
                            {isEditing ? 
                            <div className="row my-2 mx-auto">
                                <div className="col">
                                    <button className="btn btn-success w-fit" onClick={addISP} disabled={isSaving}>
                                        <i className="fas fa-plus"/>&nbsp;Add ISP
                                    </button>
                                </div>
                            </div>
                            :null}
                        </div>
                        <h5><b>Software Vendors</b></h5>
                        {!isEditing ? 
                            <>
                            {customer.technical.softwareVendors.length > 0 ? 
                            <ol className="text-start ms-5 me-5">
                                {customer.technical.softwareVendors.map((vendor, i) => <li key={i}><b>{vendor.title}</b><br/>{vendor.notes.split('\n').map((note, i) => <p key={i}>{note}</p>)}</li>)}
                            </ol>
                            :
                            <p>(None)</p>
                            }
                            </>
                        :
                            <>
                            {workingCustomer.technical.softwareVendors.map((vendor, i) => 
                                <div className="row mx-auto mb-1" key={i}>
                                    <div className="col-3">
                                        <input className="form-control" type="text" placeholder="Software Title" name={`technical.softwareVendors[${i}].title`} value={vendor.title} onChange={handleChange} onBlur={trimOnBlur}/>
                                    </div>
                                    <div className="col-8">
                                        <textarea className="form-control" placeholder="Notes" name={`technical.softwareVendors[${i}].notes`} value={vendor.notes} onChange={handleChange} disabled={isSaving || !hasWritePerm("technical")} onBlur={trimOnBlur}/>
                                    </div>
                                    <div className="col-1">
                                        <button className="btn btn-danger" onClick={(e) => {e.preventDefault(); removeSoftwareVendorAtIndex(i)}} disabled={isSaving}>
                                            <i className="fas fa-times"/>
                                        </button>
                                    </div>
                                </div>
                            )}
                            <div className="row mx-auto">
                                <div className="col">
                                    <button className="btn btn-success" onClick={addSoftwareVendor} disabled={isSaving}>
                                        <i className="fas fa-plus"/>
                                    </button>
                                </div>
                            </div>
                            </>
                        }
                        {!isEditing ? 
                            <div className="row mt-4 mx-auto">
                                <div className="col-3"/>
                                <div className="col-6 section-outline">
                                    <NotesSection header="Technical Notes" objectName={"location"} setNotes={setNotes} notes={notes} technical={true} refId={customer._id} subId={customer.locationIndex.toString()}/>
                                </div>
                                <div className="col-3"/>
                            </div>
                        :null}
                        <div className={isEditing ? "row mt-4 py-3 mx-auto" : "row mt-1 py-3 mx-auto"}>
                            <div className="col-2 text-end">
                                <b>POCs:</b>
                            </div>
                            <div className={workingCustomer.serviceTypes.msp ? "col-10 text-start" : (isEditing ? "col-10 text-start" : "col-10 d-flex flex-row flex-wrap text-start")}>
                                {isEditing ? 
                                    <>
                                    {workingCustomer.technical.pocs.map((poc, i) => <div key={i} className={i === workingCustomer.technical.pocs.length - 1 ? "row mb-1" : "row mb-4"}><div className="col d-flex justify-content-start"><div className="section-outline me-1 w-fit"><PocDisplay poc={poc} isEditing={isEditing} basePath={`technical.pocs[${i}]`} baseValue={workingCustomer} onChange={handleChange} handleDelete={(event) => {event.preventDefault(); deleteTechnicalPoc(i)}} series disabled={isSaving || !hasWritePerm('technical')} onBlur={trimOnBlur}/></div></div></div>)}
                                    <div className="row">
                                        <div className="col d-flex justify-content-start">
                                            <button className="btn btn-success" onClick={addTechnicalPoc} disabled={isSaving || !hasWritePerm('technical')}>
                                                <i className="fas fa-plus"/>
                                            </button>
                                        </div>
                                    </div>
                                    </>
                                :
                                    <>
                                    {customer.technical.pocs.length > 0 ?
                                        <>
                                        {customer.technical.pocs.map((poc, i) => <div key={i} className="section-outline me-1 mb-1 w-fit"><PocDisplay poc={poc} disabled={isSaving || !hasWritePerm('technical')} onBlur={trimOnBlur}/></div>)}
                                        </>
                                    :
                                        <p>(None)</p>
                                    }
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    {workingCustomer.serviceTypes.internet ?
                    <div className="row mt-1 section-outline">
                        <h4><b>Internet Information</b></h4>
                        <div className="row">
                            <div className="col-6">
                                <div className="row mt-1">
                                    <div className="col-3 text-end">
                                        <b>Provider:</b>
                                    </div>
                                    <div className="col-9 text-start">
                                        {!isEditing ? 
                                            <p>{customer.technical.provider === "NOT SET" ? "(Not Set)" : customer.technical.provider}</p>
                                        :
                                        <div className="row">
                                            <div className="col">
                                                <select className="form-select w-100" name="technical.provider" value={workingCustomer.technical.provider} onChange={handleChange} disabled={isSaving}>
                                                    <option value="NOT SET">-- None Selected --</option>
                                                    <option value="BPUD">BPUD</option>
                                                    <option value="Noanet">Noanet</option>
                                                    <option value="FPUD">FPUD</option>
                                                    <option value="COR">COR</option>
                                                    <option value="CBIT">CBIT</option>
                                                </select>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-3 text-end">
                                        <b>Management IP:</b>
                                    </div>
                                    <div className="col-9 text-start">
                                        {!isEditing ? 
                                            <p>
                                                {customer.technical.managementIp ? customer.technical.managementIp : "(None)"}
                                            </p>
                                        :
                                            <input className="form-control" type="text" name="technical.managementIp" value={workingCustomer.technical.managementIp} onChange={handleChange} onBlur={trimOnBlur}/>
                                        }
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-3 text-end">
                                        <b>CIDs:</b>
                                    </div>
                                    <div className="col-9 text-start">
                                        {!isEditing ? 
                                            <>
                                            {customer.technical.cids.length > 0 ?
                                            <ol className="ps-3">
                                                {customer.technical.cids.map((cid, i) => <li key={i}><code>{cid}</code></li>)}
                                            </ol>
                                            :
                                            <p>
                                                (None)
                                            </p>
                                            }
                                            </>
                                        :
                                            <>
                                            {techStringEditing('cids', 'CID')}
                                            </>
                                        }
                                    </div>
                                </div>
                                {!workingCustomer.technical.dhcp && workingCustomer.technical.provider !== "COR" && workingCustomer.technical.provider !== "CBIT" ? 
                                <div className="row mt-1">
                                    <div className="col-3 text-end">
                                        <b>Gateways:</b>
                                    </div>
                                    <div className="col-9 text-start">
                                        {!isEditing ? 
                                            <>
                                            {customer.technical.gateways.length > 0 ?
                                            <ol className="ps-3">
                                                {customer.technical.gateways.map((gateway, i) => <li key={i}><code>{gateway}</code></li>)}
                                            </ol>
                                            :
                                            <p>
                                                (None)
                                            </p>
                                            }
                                            </>
                                        :
                                            <>
                                            {techStringEditing('gateways', 'Gateway')}
                                            </>
                                        }
                                    </div>
                                </div>
                                :null}
                            </div>
                            <div className="col-6">
                                <div className="row mt-1">
                                    <div className="col-3 text-end">
                                        <b>Service:</b>
                                    </div>
                                    <div className="col-9 text-start">
                                        {!isEditing ? 
                                            <p>{customer.technical.service === "NOT SET" ? "(Not Set)" : customer.technical.service}</p>
                                        :
                                        <div className="row">
                                            <div className="col">
                                                <select className="form-select w-100" name="technical.service" value={workingCustomer.technical.service} onChange={handleChange} disabled={isSaving}>
                                                    <option value="NOT SET">-- None Selected --</option>
                                                    <option value="100 Mbps">100 Mbps</option>
                                                    <option value="200 Mbps">200 Mbps</option>
                                                    <option value="250 Mbps">250 Mbps</option>
                                                    <option value="500 Mbps">500 Mbps</option>
                                                    <option value="1 Gbps">1 Gbps</option>
                                                    <option value="2 Gbps">2 Gbps</option>
                                                    <option value="30 Gbps">30 Gbps</option>
                                                </select>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-3 text-end">
                                        <b>DHCP?:</b>
                                    </div>
                                    <div className="col-9 text-start">
                                        {!isEditing ? 
                                        <p>{customer.technical.dhcp ? 'True' : 'False'}</p>   
                                        :
                                        <input className="form-check-input" name="technical.dhcp" type="checkbox" checked={workingCustomer.technical.dhcp} onChange={handleChange} disabled={isSaving || !hasWritePerm('technical')}/>
                                        }
                                    </div>
                                </div>
                                {!workingCustomer.technical.dhcp && workingCustomer.technical.provider !== "COR" && workingCustomer.technical.provider !== "CBIT" ? 
                                <>
                                <div className="row mt-1">
                                    <div className="col-3 text-end">
                                        <b>IPs:</b>
                                    </div>
                                    <div className="col-9 text-start">
                                        {!isEditing ? 
                                            <>
                                            {customer.technical.ips.length > 0 ?
                                            <ol className="ps-3">
                                                {customer.technical.ips.map((ip, i) => <li key={i}><code>{ip}</code></li>)}
                                            </ol>
                                            :
                                            <p>
                                                (None)
                                            </p>
                                            }
                                            </>
                                        :
                                            <>
                                            {techStringEditing('ips', 'IP')}
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className="row mt-1">
                                    <div className="col-3 text-end">
                                        <b>Subnet Masks:</b>
                                    </div>
                                    <div className="col-9 text-start">
                                        {!isEditing ? 
                                            <>
                                            {customer.technical.subnetMasks.length > 0 ?
                                            <ol className="ps-3">
                                                {customer.technical.subnetMasks.map((mask, i) => <li key={i}><code>{mask}</code></li>)}
                                            </ol>
                                            :
                                            <p>
                                                (None)
                                            </p>
                                            }
                                            </>
                                        :
                                            <>
                                            {techStringEditing('subnetMasks', 'Subnet')}
                                            </>
                                        }
                                    </div>
                                </div>
                                </>
                                :null}
                            </div>
                        </div>
                        
                        {!workingCustomer.technical.dhcp && (workingCustomer.technical.provider === "COR" || workingCustomer.technical.provider === "CBIT") ? 
                        <div className="row mt-1">
                            <div className="col-2 text-end">
                                <b>IP Table:</b>
                            </div>
                            <div className="col-10 text-start">
                                <IPListTable claims={ipClaims} setClaims={setIpClaims} claimantObjectType="CustomerLocation" claimantId={workingCustomer.locations[workingCustomer.locationIndex]._id.toString()} isEditing={isEditing} disabled={isSaving}/>
                            </div>
                        </div>
                        :null}
                        
                        <div className="row mt-1">
                            <div className="col-2 text-end">
                                <b>Equipment Location:</b>
                            </div>
                            <div className="col-10 text-start">
                                {!isEditing ? 
                                    <>
                                    {customer.technical.equipmentLocation !== "" ? 
                                        <>{customer.technical.equipmentLocation.split('\n').map((parag, i) => <p key={i}>{parag}</p>)}</>
                                    :
                                        <p>(None)</p>
                                    }
                                    </>
                                :
                                    <textarea className="form-control" placeholder="Location" name="technical.equipmentLocation" value={workingCustomer.technical.equipmentLocation} onChange={handleChange} disabled={isSaving || !hasWritePerm("technical")} onBlur={trimOnBlur}/>
                                }
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-2 text-end">
                                <b>Service Port:</b>
                            </div>
                            <div className="col-10 text-start">
                                {!isEditing ? 
                                    <>
                                    {customer.technical.servicePort !== "" ? 
                                        <>{customer.technical.servicePort.split('\n').map((parag, i) => <p key={i}>{parag}</p>)}</>
                                    :
                                        <p>(None)</p>
                                    }
                                    </>
                                :
                                    <textarea className="form-control" placeholder="Service Port" name="technical.servicePort" value={workingCustomer.technical.servicePort} onChange={handleChange} disabled={isSaving || !hasWritePerm("technical")} onBlur={trimOnBlur}/>
                                }
                            </div>
                        </div>
                        {!isEditing ? 
                        <div className="row mt-2">
                            <div className="col">
                                <button className="btn btn-primary" onClick={openEmailModal}>
                                    <i className="fas fa-envelope"/>&nbsp;{workingCustomer.technical.emailSentDate ? "Send Customer Info Again" : "Send Customer Info"}
                                </button>
                                {workingCustomer.technical.emailSentDate ? <i className="text-muted ms-2">Email was sent to customer on {(new Date(workingCustomer.technical.emailSentDate)).toLocaleString()}</i> : null}
                            </div>
                        </div>
                        :null}
                    </div>
                    :null}
                    {workingCustomer.serviceTypes.voip ? 
                    <div className="row mt-1 section-outline">
                        <h4><b>VoIP Information</b></h4>
                        <div className="row mt-1">
                            <div className="col-6">
                                <div className="row">
                                    <div className="col-3 text-end">
                                        <span>
                                            <b># of Lines</b>&nbsp;
                                            <div className="tooltip-container">
                                                <span className="tooltip-text-bottom">Maximum simultaneous calls (a parameter specifically needed for FCC 477 reports)</span>
                                                <i className="fas fa-circle-question"/>
                                            </div>
                                            <b>:</b>
                                        </span>
                                    </div>
                                    <div className="col-9 text-start">
                                        {!isEditing ? 
                                            <span>{customer.technical.voipLines}</span>
                                        :
                                            <input className="form-control" type="number" name="technical.voipLines" value={workingCustomer.technical.voipLines} onChange={handleChange} min={0} step={1} disabled={isSaving || !hasWritePerm('technical')}/>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                {!isEditing ? 
                                    <div className="row">
                                        <div className="col-3 text-end">
                                            <b># of Devices:</b>
                                        </div>
                                        <div className="col-9 text-start">
                                            {customer.integrationIds.unity ? <UnityDevicesCounter domain={customer.integrationIds.unity}/> : <i className="text-muted">Unity integration is required.</i>}
                                        </div>
                                    </div>
                                :null}
                            </div>
                        </div>
                        {!isEditing ? 
                            <div className="row mt-1">
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-3 text-end">
                                            <b>DIDs:</b>
                                        </div>
                                        <div className="col-9 text-start">
                                            {customer.integrationIds.unity ? <UnityDIDList domain={customer.integrationIds.unity}/> : <i className="text-muted">Unity integration is required.</i>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-3 text-end">
                                            <b>Fax Numbers:</b>
                                        </div>
                                        <div className="col-9 text-start">
                                            {customer.integrationIds.unity ? <UnityFaxList domain={customer.integrationIds.unity}/> : <i className="text-muted">Unity integration is required.</i>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        :null}
                    </div>
                    :null}
                </>
                :null}
                <div className="row mt-1 text-start monospace">
                    <div className="col text-start monospace">
                        <span>Created by {customer.createdBy} on {(new Date(customer.createdAt)).toLocaleString()}</span><br/>
                        <span>Updated by {customer.modifiedBy} on {(new Date(customer.updatedAt)).toLocaleString()}</span>
                    </div>
                    <div className="col text-end">
                        {tags !== null ? <>
                            {isEditing ? 
                                <>
                                    {tags.map((tag, i) => <div key={i}>
                                        <input type="checkbox" name={`tag-check${i}`} className="btn-check" autoComplete='off' id={`tag-check${i}`} checked={workingCustomer.tags.includes(tag._id)} onChange={handleChange} disabled={isSaving}/>
                                        <label className="btn btn-outline-secondary btn-sm me-1 pt-2" htmlFor={`tag-check${i}`}>{tag.name}</label>
                                    </div>)}
                                </>
                            :
                                <>
                                    {customer.tags.map((tagId, i) => <span key={i} className="badge bg-secondary me-1">{getTagById(tagId) ? getTagById(tagId).name : "(error)"}</span>)}
                                </>
                            }
                        </> : null}
                    </div>
                </div>
                </>
            : <LoadingSpinner size={75}/> }
        </>
    )
}

const CustomerPage = props => {
    return (
        <div className="container pb-5">
            <BannerLog>
                <CustomerPageBody />
            </BannerLog>
        </div>
    )
}

export default CustomerPage;