import React, {useContext} from 'react';

import ModalContext from '../ModalContext';
import ExportModal from '../ExportModal';
import ProductOrderFormModal from './ProductOrderFormModal';
import UserContext from '../../UserContext';
import AlertsContext from '../AlertsContext';
import Tooltip from '../Tooltip';
import ObjectTable from '../ObjectTable';

const getDescriptionPreview = (order) => {
    return order.items[0].description.substring(0, 20) + (order.items[0].description.length > 20 ? '...' : '');
}

const ProductOrderRow = ({object, clickedObject}) => {
    const alertsContext = useContext(AlertsContext);
    const statusColor = (status) => {
        switch(status) {
            case "New":
                return "info";
            case "Ordered":
                return "success";//"warning";
            case "Backordered":
                return "dark";
            case "Ready to Pay Vendor":
            case "Pending":
                return "warning";//"secondary";
            case "Disconnect":
            case "Cancelled":
                return "danger";
            case "Received":
                return "primary";
            case "Completed":
                return "secondary";//"success";
            default:
                return "light";
        }
    }

    const typeColor = (type) => {
        switch(type) {
            case "New":
                return "info";
            case "From Stock":
                return "warning";
            case "Disconnect":
            case "Return":
                return "danger";
            default:
                return "light";
        }
    }

    const renderFlag = () => {
        const reason = alertsContext.orderReason(object._id, 'product');
        if(!reason) return null;
        else return <>&nbsp;<Tooltip text={reason}><i className="fas fa-flag text-danger"/></Tooltip></>
    }

    return (
        <>
            <td className={`cursor-pointer table-${typeColor(object.type)}`} onClick={(event) => {event.preventDefault(); clickedObject(object)}}>
                {object.type}
            </td>
            <td className={`cursor-pointer table-${statusColor(object.status)}`} onClick={(event) => {event.preventDefault(); clickedObject(object)}}>
                {object.status === "New" ? "Estimate" : object.status}
            </td>
            <td className="cursor-pointer" onClick={(event) => {event.preventDefault(); clickedObject(object)}}>
                {object.customer.displayName}{renderFlag()}
            </td>
            <td className="cursor-pointer" onClick={(event) => {event.preventDefault(); clickedObject(object)}}>
                <div className="tooltip-container border-0">
                    <span className="tooltip-text p-2">
                        {object.items.map((item, i) => `Item ${i+1}: ${item.description}`).join('\n\n')}
                    </span>
                    {getDescriptionPreview(object)}
                </div>
            </td>
            <td className="cursor-pointer" onClick={(event) => {event.preventDefault(); clickedObject(object)}}>
                {object.qbInvoiceNumber}
            </td>
            <td className="cursor-pointer" onClick={(event) => {event.preventDefault(); clickedObject(object)}}>
                {object.qbInvoiceId ? (object.qbInvoiceStatus !== "NOT SET" ? object.qbInvoiceStatus : "(Check QB)") : ""}
            </td>
            <td className="cursor-pointer" onClick={(event) => {event.preventDefault(); clickedObject(object)}}>
                {object.datePurchased ? (new Date(object.datePurchased)).toLocaleDateString() : ''}
            </td>
            <td className="cursor-pointer" onClick={(event) => {event.preventDefault(); clickedObject(object)}}>
                {object.bundleNumber}
            </td>
            <td className="cursor-pointer" onClick={(event) => {event.preventDefault(); clickedObject(object)}}>
                {object.ticketNumber}
            </td>
        </>
    )
}

/**
 * 
 * @param {Array} props.orders The list of orders to display
 * @param {Function} props.clickedOrder A function that takes the clicked order as an argument
 */
const ProductOrdersTable = props => {
    const modaling = useContext(ModalContext);
    const userContext = useContext(UserContext);
    const alerts = useContext(AlertsContext);

    const openNewOrderForm = (_) => {
        modaling.setModal(null);
        modaling.setModal(<ProductOrderFormModal parentBanners={props.parentBanners} modalContext={modaling} onClose={() => {props.setOrders(null); alerts.reload()}}/>)
    }

    const openNewOrderEstimateForm = (_) => {
        modaling.setModal(null);
        modaling.setModal(<ProductOrderFormModal isEstimate parentBanners={props.parentBanners} modalContext={modaling} onClose={() => {props.setOrders(null); alerts.reload()}}/>)
    }

    /** TODO: Implement exporting for ProductOrders */
    const openExportModal = (selectedObjects) => {
        modaling.setModal(<ExportModal objects={selectedObjects} model="productOrder"/>);
    }

    return (
        <ObjectTable 
            id="productOrdersTable"
            cols={[
                {
                    label: 'Type', 
                    sort: (ascending) => {
                        return (a, b) => {
                            const values = {
                                'New': 0,
                                'From Stock': 1,
                                'Return': 2,
                                'Disconnect': 3,
                            }
                            return values[`${a.type}`] < values[`${b.type}`] ? (ascending ? -1 : 1) : (ascending ? 1 : -1);
                        }
                    }
                },
                {
                    label: 'Status',
                    sort: (ascending) => {
                        return (a, b) => {
                            const values = {
                                'NOT SET': 0,
                                'New': 1,
                                'Ready to Pay Vendor': 2,
                                'Approved': 3,
                                'Ordered': 4,
                                'Pending': 5,
                                'Backordered': 6,
                                'Cancelled': 7,
                                'Received': 8,
                                'Completed': 9,
                                'Disconnect': 10
                            }
                            return values[`${a.status}`] < values[`${b.status}`] ? (ascending ? -1 : 1) : (ascending ? 1 : -1);
                        }
                    }
                },
                {
                    label: 'Customer Name',
                    sort: (ascending) => {
                        return (a, b) => a.customer.displayName < b.customer.displayName ? (ascending ? -1 : 1) : (ascending ? 1 : -1)
                    }
                },
                {
                    label: 'Description',
                    sort: (ascending) => {
                        return (a, b) => getDescriptionPreview(a) < getDescriptionPreview(b) ? (ascending ? -1 : 1) : (ascending ? 1 : -1)
                    }
                },
                {
                    label: 'QB Invoice #',
                    sort: (ascending) => {
                        return (a, b) => a.qbInvoiceNumber < b.qbInvoiceNumber ? (ascending ? -1 : 1) : (ascending ? 1 : -1)
                    }
                },
                {
                    label: 'QB Invoice Status',
                    sort: (ascending) => {
                        return (a, b) => {
                            const values = {
                                'Paid': 0,
                                'Unpaid': 1,
                                'NOT SET': 2,
                                '': 3
                            }
                            return values[`${a.status}`] < values[`${b.status}`] ? (ascending ? -1 : 1) : (ascending ? 1 : -1);
                        }
                    }
                },
                {
                    label: 'Date Purchased',
                    sort: (ascending) => {
                        return (a, b) => a.datePurchased < b.datePurchased ? (ascending ? -1 : 1) : (ascending ? 1 : -1)
                    }
                },
                {
                    label: 'PO #',
                    sort: (ascending) => {
                        return (a, b) => a.number < b.number ? (ascending ? -1 : 1) : (ascending ? 1 : -1)
                    }
                },
                {
                    label: 'Ticket #',
                    sort: (ascending) => {
                        return (a, b) => a.ticketNumber < b.ticketNumber ? (ascending ? -1 : 1) : (ascending ? 1 : -1)
                    }
                }
            ]}
            objects={props.orders}
            actions={[
                {label: 'Export', func: openExportModal},
            ]}
            filters={[
                {label: 'My Orders', value: 'Mine', func: (order) => order.status !== "Completed" && order.status !== "Received" && order.blame.createdBy.email === userContext.user.email},
                {label: 'All Active Orders', value: 'All', func: (order) => order.status !== "Completed" && order.status !== "Received"},
                {label: 'Archived Orders', value: 'Archived', func: (order) => (order.status === "Completed" || order.status === "Received")},
                {label: 'Everything', value: 'Everything', func: (_) => true},
            ]}
            btns={[
                {label: 'Request', func: openNewOrderEstimateForm},
                {label: 'Order', func: openNewOrderForm}
            ]}
            rowElement={ProductOrderRow}
            clickedObject={props.clickedOrder}
            search
            paginated
            defaultSortByColName="Status"
            defaultFilterSetting="Mine"
            defaultSortAscending={true}
        />
    )
}

export default ProductOrdersTable