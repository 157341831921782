import React, {useState, useEffect, useContext} from 'react';
import UserContext from '../UserContext';
import chimera from '../chimera';

const AlertsContext = React.createContext();

const AlertsContextProvider = props => {
    const [alerts, setAlerts] = useState(null);
    const userContext = useContext(UserContext);

    useEffect(() => {
        if(userContext.user && alerts === null) {
            reload();
        }
    }, [userContext.user, alerts]);

    const reload = () => {
        if(userContext.user) {
            chimera.callAPI(undefined, '/api/alerts')
            .then(alerts => {
                setAlerts(alerts);
            })
            .catch(err => {
                console.error(err);
                alert("Error reading alerts");
            })
        }
    }

    const getOrdersTotal = () => {
        if(alerts) {
            return alerts.orders.total;
        }
        else {
            return 0;
        }
    }

    const allArrayObjects = (obj) => {
        if(!obj) return [];
        let allObjects = [];
        for(const key in obj) {
            if(typeof obj[key] === 'object' && JSON.stringify(obj[key]).startsWith('[')) {
                allObjects = allObjects.concat(obj[key]);
            }
            else if(typeof obj[key] === 'object') {
                allObjects = allObjects.concat(allArrayObjects(obj[key]));
            }
        }
        return allObjects;
    }

    const isFlagged = (object) => {
        if(alerts === null || !object._id) return false;
        const allObjects = allArrayObjects(alerts);
        for(const flaggedObject of allObjects) {
            if(flaggedObject._id === object._id) {
                return true;
            }
        }
        return false;
    }

    /**
     * 
     * @param {String} orderId The MongoDB ObjectID string
     * @param {String} kind One of 'internet', 'voip', 'product', 'msp'
     * @returns {String} The reason for the alert, if any (empty string if none)
     */
    const orderReason = (orderId, kind) => {
        if(alerts) {
            kind = kind.toLowerCase();
            const arrayName = `${kind}Orders`;
            const arraysPerReason = alerts.orders[arrayName];
            const reasonsPerKind = {
                internet: ['renewalPending', 'checkInDate', 'flagged', 'waitingOnCustomer', 'delayed'],
                product: ['new', 'paidInvoice'],
                voip: ['flagged', 'delay', 'waitingOnCustomer'],
                msp: ['flagged']
            }
            const englishReasons = {
                renewalPending: 'Renewal Required',
                checkInDate: 'Check-In',
                new: 'New Order',
                paidInvoice: 'Invoice Paid; Update Status',
                flagged: '(See Notes)',
                delay: 'Status: "Delay"',
                waitingOnCustomer: 'Status: "Waiting on Customer"',
                delayed: 'Status: Delayed'
            }
            const arrIncludesOrderId = (arr, id) => (arr.find(o => o._id === id) ? true : false);
            
            for(const reason of reasonsPerKind[kind]) {
                if(arrIncludesOrderId(arraysPerReason[reason], orderId)) {
                    return englishReasons[reason];
                }
            }
            return '';
        }
        else {
            return '';
        }
    }

    return <>
        <AlertsContext.Provider value={{
            alerts,
            setAlerts,
            reload,
            getOrdersTotal,
            orderReason,
            isFlagged
        }}>
            {props.children}
        </AlertsContext.Provider>
    </>
}

export { AlertsContext as default, AlertsContextProvider };