import React, {useState, useLayoutEffect, useContext} from "react";
import taxcalcimg from "../../images/taxcalcimg.png";
import ToolPage from './ToolPage';
import TSPairInterface from "../TSPairInterface";
import LoadingSpinner from "../LoadingSpinner";
import chimera from "../../chimera";
import BannerLogContext from "../BannerLogContext";
import ObjectTable from "../ObjectTable";

// TODO: Remove Blacklisting when all customers are being taxed.
// SEE ALSO: The other TODO where the Blacklist is applied.
// This is a Blacklist of QB customer IDs.
const BLACKLIST = [
    '18', //Gamache Landscaping
    '34', //WA Vision Therapy
    '500', //Liberation Bike Shop
    '56', //Sundance Aviation
    '528', //Uniwest
    '32', //Yakama Power
]

const TxnRow = ({object, clickedObject}) => {
    return (
        <>
            <td className="cursor-pointer" onClick={(event) => {event.preventDefault(); clickedObject(object)}}>
                {object.type}
            </td>
            <td className="cursor-pointer" onClick={(event) => {event.preventDefault(); clickedObject(object)}}>
                {object.doc.CustomerRef.name}
            </td>
            <td className="cursor-pointer" onClick={(event) => {event.preventDefault(); clickedObject(object)}}>
                {object.doc.RecurringInfo.Name}
            </td>
            <td className="cursor-pointer" onClick={(event) => {event.preventDefault(); clickedObject(object)}}>
                {object.doc.RecurringInfo.Active ? 'Active' : 'Paused'}
            </td>
        </>
    )
}

const TaxCalcBody = props => {
    const [showGoBtn, setShowGoBtn] = useState(true);
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [progressBarLabel, setProgressBarLabel] = useState("Working...");
    const [showResults, setShowResults] = useState(false);
    const [urls, setUrls] = useState([]);
    const [emailBtnIcon, setEmailBtnIcon] = useState("fas fa-envelope");
    const [emailBtnLabel, setEmailBtnLabel] = useState("Email Me");
    const [emailBtnDisabled, setEmailBtnDisabled] = useState(false);
    const [emailResponseText, setEmailResponseText] = useState(null);
    const [showTxnChoice, setShowTxnChoice] = useState(false);
    const [txns, setTxns] = useState([]);
    const [controller] = useState(new AbortController());
    const [signal] = useState(controller.signal);
    const banners = useContext(BannerLogContext);

    useLayoutEffect(() => {
        return () => {
            controller.abort();
        }
    }, []);

    const roundUp = (num, precision) => {
        precision = Math.pow(10, precision);
        return Math.ceil(num * precision) / precision;
    }

    const renderUrls = () => {
        let invoiceLinks = [];
        let salesReceiptLinks = [];
        let n = 0;
        for(const url of urls) {
            if(url.url.includes("invoice")) {
                invoiceLinks.push(
                    <li key={n}>
                        <a href={url.url} target="_blank" rel="noopener noreferrer">Invoice {url.number} ({url.customer})</a>
                    </li>
                );
                n++
            }
            else if(url.url.includes("salesreceipt")) {
                salesReceiptLinks.push(
                    <li key={n}>
                        <a href={url.url} target="_blank" rel="noopener noreferrer">Sales Receipt {url.number} ({url.customer})</a>
                    </li>
                );
                n++;
            }
        }
        return (
            <>
            <h5>
                Invoices
            </h5>
            {invoiceLinks.length > 0 ? 
            <ol>
                {invoiceLinks}
            </ol>
            :
            <p className="text-muted"><i>There were no invoices generated.</i></p>
            }
            <h5>
                Sales Receipts
            </h5>
            {salesReceiptLinks.length > 0 ? 
            <ol>
                {salesReceiptLinks}
            </ol>
            :
            <p className="text-muted"><i>There were no sales receipts generated.</i></p>
            }
            </>
        )
    }

    const resetTool = () => {
        setShowGoBtn(true);
        setShowProgressBar(false);
        setProgressBarLabel("Working...");
        setShowResults(false);
        setUrls([]);
        setEmailBtnIcon("fas fa-envelope");
        setEmailBtnLabel("Email Me");
        setEmailBtnDisabled(false);
        setEmailResponseText(null);
        setShowTxnChoice(false);
        setTxns([]);
        banners.clearBanners();
    }

    const emailUrls = async() => {
        setEmailBtnIcon("fas fa-spinner");
        setEmailBtnLabel("Sending...");
        setEmailBtnDisabled(true);
        const urlsList = document.getElementById("urls").innerHTML;
        let text = "";
        for(const url of urls) {
            text += `${url.customer}:\n`;
            text += `${url.url}\n\n`;
        }

        try {
            await chimera.callAPI(signal, '/api/sendmail', 'POST', {
                subject: "Transactions Generated by Tax Calculator",
                text: text,
                html: urlsList
            });
            const jsx = <span className="text-success">Sent! You should receive an email shortly.</span>
            setEmailResponseText(jsx);
        }
        catch(e) {
            if(e.name !== "AbortError") {
                console.error(e);
                const jsx = <span className="text-danger">Oops! Something went wrong. Please try again or contact the site administrator if the problem persists.</span>
                setEmailResponseText(jsx);
            }
        }
        finally {
            setEmailBtnIcon("fas fa-envelope");
            setEmailBtnLabel("Email Me");
            setEmailBtnDisabled(false);
        }
    }

    const getChimeraCustomers = async() => {
        let chimeraCustomers = await chimera.CommercialCustomer.getByServiceType('voip', signal);
        for(const chimeraCustomer of chimeraCustomers) {
            if(!chimeraCustomer.integrationIds.quickbooks) {
                banners.addBanner('warning', `${chimeraCustomer.displayName} is a VoIP customer but lacks a QuickBooks integration, so they cannot be included by this tool.`, 'Warning');
            }
        }
        chimeraCustomers = chimeraCustomers.filter((customer) => customer.integrationIds.quickbooks);
        return chimeraCustomers;
    }

    const findChimeraCustomerForQbCustomer = (qbCustomer, chimeraCustomers) => {
        for(const chimeraCustomer of chimeraCustomers) {
            if(chimeraCustomer.integrationIds.quickbooks === qbCustomer.Id) {
                return chimeraCustomer;
            }
        }
        return null;
    }

    const getAvalaraLineItemsFromQbTransaction = (txn, tspairs, tspairQbItems) => {
        const transaction = txn.doc;
        let itms = [];
        //console.log(transaction);
        for(const lineItem of transaction.Line) {
            if(lineItem.SalesItemLineDetail) {
                let tokens = lineItem.SalesItemLineDetail.ItemRef.name.split(':');
                let itemName = tokens[tokens.length-1];
                for(const tspair of tspairs) {
                    //console.log(`comparing tspair.item ${tspair.item} to itemName ${itemName}`);
                    if(tspair.item === itemName) {
                        //console.log(`matched tspair.item ${tspair.item} with itemName ${itemName}`);
                        let item = {
                            chg: lineItem.Amount,
                            line: lineItem.SalesItemLineDetail.Qty,
                            sale: 1,
                            tran: tspair.t,
                            serv: tspair.s
                        }
                        for(const tspairQbItem of tspairQbItems) {
                            if(tspairQbItem.item === itemName && lineItem.Amount < tspairQbItem.price) {
                                item.pror = lineItem.Amount / tspairQbItem.price;
                                //console.log(`added pror of ${item.pror}`);
                                break;
                            }
                        }
                        itms.push(item);
                        break;
                    }
                }
            }
        }
        return itms;
    }

    const createTransaction = async(transactionGroup, txn, taxTransactions, net15, taxesItemRef, chimeraCustomers) => {
        const expectedTaxNames = ["Sales Tax", "Utility Users Tax", "E-988 (VoIP)", "E911 (VoIP)", "FUSF (VoIP)", "FCC Regulatory Fee (VoIP)"];
        let actualTaxNames = [];
        const transaction = txn.doc;
        let finalLineItems = [];
        const chimeraCustomer = findChimeraCustomerForQbCustomer(transactionGroup.customer, chimeraCustomers);
        let includeTaxes = true;
        if(chimeraCustomer && chimeraCustomer.taxExempt) {
            includeTaxes = false;
        }
        for(const lineItem of transaction.Line) {
            if(lineItem.DetailType !== "SubTotalLineDetail")
                finalLineItems.push(lineItem);
        }
        if(txn.newDocNumber) {
            if(includeTaxes) {
                for(const taxTransaction of taxTransactions) {
                    //console.log(`comparing ${taxTransaction.doc} to ${txn.newDocNumber}`);
                    if(taxTransaction.doc === txn.newDocNumber) {
                        //console.log("match");
                        //console.log(taxTransaction);
                        if(!taxTransaction.err) {
                            for(const itm of taxTransaction.itms) {
                                for(const tax of itm.txs) {
                                    // Add a SalesItemLineDetail with item "Telecom Taxes & Fees"
                                    // Qty at 1, Rate at `tax.tax` (rounded up), Amount to match
                                    if(tax.bill) {
                                        const amount = roundUp(tax.tax, 2);
                                        let taxNameIsPresent = false;
                                        for(let i = 0; i < finalLineItems.length; i++) {
                                            if(finalLineItems[i].Description === tax.name) {
                                                taxNameIsPresent = true;
                                                finalLineItems[i].Amount += amount;
                                                finalLineItems[i].SalesItemLineDetail.UnitPrice += amount;
                                            }
                                        }
                                        if(!taxNameIsPresent) {
                                            finalLineItems.push({
                                                DetailType: "SalesItemLineDetail",
                                                Description: tax.name,
                                                Amount: amount,
                                                SalesItemLineDetail: {
                                                    Qty: 1.0,
                                                    UnitPrice: amount,
                                                    ItemRef: taxesItemRef,
                                                    ClassRef: {
                                                        name: 'VoIP Taxes',
                                                        value: '200000000001780942'
                                                    }
                                                }
                                            });
                                            actualTaxNames.push(tax.name);
                                        }
                                    }
                                }
                            }
                        }
                        else {
                            console.error(taxTransaction);
                            banners.addBanner('danger', `Avalara API request body for ${transactionGroup.customer.DisplayName} contains an error. Verify T/S pairs. The transaction ${txn.newDocNumber} will be created but will be missing taxes.`, 'Error');
                        }
                        break;
                    }
                }
                //console.log(actualTaxNames);
                for(const expectedTaxName of expectedTaxNames) {
                    if(!actualTaxNames.includes(expectedTaxName)) {
                        banners.addBanner('warning', `Expected VoIP tax "${expectedTaxName}" not returned by Avalara for invoice ${txn.newDocNumber} for ${transactionGroup.customer.DisplayName}, it will not be included in the generated QB invoice.`, 'Warning');
                    }
                }
                for(const actualTaxName of actualTaxNames) {
                    if(!expectedTaxNames.includes(actualTaxName)) {
                        banners.addBanner('warning', `Unexpected VoIP tax "${actualTaxName}" returned by Avalara for invoice ${txn.newDocNumber} for ${transactionGroup.customer.DisplayName}, it will be included in the generated QB invoice.`, 'Warning');
                    }
                }
            }
            //console.log(finalLineItems);
            const body = txn.type === "Invoice" ? {
                Line: finalLineItems,
                CustomerRef: {
                    name: transactionGroup.customer.DisplayName,
                    value: transactionGroup.customer.Id
                },
                DocNumber: txn.newDocNumber,
                BillEmail: {
                    Address: transactionGroup.customer.PrimaryEmailAddr.Address
                }
            } : JSON.parse(JSON.stringify(transaction));

            try {
                if(txn.type === "SalesReceipt") {
                    delete body.Id;
                    delete body.MetaData;
                    delete body.RecurDataRef;
                    delete body.RecurringInfo;
                    body.Line = finalLineItems;
                    body.CustomerRef = {
                        name: transactionGroup.customer.DisplayName,
                        value: transactionGroup.customer.Id
                    }
                    body.DocNumber = txn.newDocNumber;
                    body.BillEmail = {
                        Address: transactionGroup.customer.PrimaryEmailAddr.Address
                    }
                    //console.log(body);
                }
            }
            catch(err) {
                console.error(transactionGroup);
                throw err;
            }

            // Forces use of Net 15 if it was found.
            if(net15) {
                body.SalesTermRef = {
                    name: net15.Name,
                    value: net15.Id
                };
            }
            try {
                if(txn.type === "Invoice") {
                    const newTransaction = await chimera.callQuickBooksAPI(signal, '/api/qb/newinvoice', 'POST', body);
                    return {
                        url: `https://app.qbo.intuit.com/app/invoice?txnId=${newTransaction.Id}`,
                        customer: newTransaction.CustomerRef.name,
                        number: newTransaction.DocNumber
                    }
                }
                else if(txn.type === "SalesReceipt") {
                    const newTransaction = await chimera.callQuickBooksAPI(signal, '/api/qb/salesreceipt', 'POST', body);
                    return {
                        url: `https://app.qbo.intuit.com/app/salesreceipt?txnId=${newTransaction.Id}`,
                        customer: newTransaction.CustomerRef.name,
                        number: newTransaction.DocNumber
                    };
                }
            }
            catch(e) {
                if(e.name !== "AbortError") {
                    console.error(e);
                    banners.addBanner('warning', `Failed to create transaction for ${transactionGroup.customer.DisplayName} using template ${transaction.Id}`);
                }
            }
        }
    }

    const runJob = async(selectedTxns) => {
        setShowProgressBar(true);
        setShowGoBtn(false);
        setShowTxnChoice(false);
        banners.clearBanners();

        try {
            let qbCustomers = [];
            setProgressBarLabel("Getting all Chimera VoIP customers...");
            let chimeraCustomers = await getChimeraCustomers();
            if(selectedTxns === null) {
                setProgressBarLabel(`Getting all QuickBooks customer records for VoIP customers in Chimera...`)
                try {
                    //customers = await chimera.callQuickBooksAPI(signal, `/api/qb/customers/type/${customerType}`);
                    const qbResponse = await chimera.callQuickBooksAPI(signal, '/api/qb/customer/getByIdList', 'POST', {ids: chimeraCustomers.map(customer => customer.integrationIds.quickbooks)});
                    qbCustomers = qbResponse.QueryResponse.Customer;

                    // TODO: Remove this step when the Blacklist is obsolete.
                    qbCustomers = qbCustomers.filter((customer) => !BLACKLIST.includes(customer.Id));
                }
                catch(e) {
                    if(e.name !== "AbortError") {
                        console.error(e);
                        alert(`ERROR: An error occurred while fetching QuickBooks customers. The job cannot continue.`);
                        resetTool();
                    }
                    return;
                }
            }

            setProgressBarLabel("Getting T/S Pairs from Chimera...");
            let tspairs = [];
            try {
                tspairs = await chimera.callAPI(signal, '/api/tspairs');
            }
            catch(e) {
                if(e.name !== "AbortError") {
                    console.error(e);
                    alert(`ERROR: An error occurred while fetching T/S pairs. The job cannot continue.`);
                    resetTool();
                }
                return;
            }

            setProgressBarLabel("Getting items associated with Chimera T/S Pairs from QuickBooks...");
            let tspairQbItems = [];
            try {
                tspairQbItems = await chimera.callAPI(signal, '/api/qb/items', 'POST', {whitelist: tspairs.map(tspair => tspair.item)});
            }
            catch(e) {
                if(e.name !== "AbortError") {
                    console.error(e);
                    alert(`ERROR: An error occurred while fetching T/S Pair items from QuickBooks. The job cannot continue.`);
                    resetTool();
                }
                return;
            }

            let startNumber = 30001;
            try {
                startNumber = (await chimera.callAPI(signal, '/api/attributes/DOCNUMBER_START')).value;
            }
            catch(e) {
                if(e.name !== "AbortError") {
                    console.error(e);
                    alert(`ERROR: An error occurred while fetching the next DocNumber. The job cannot continue.`);
                    resetTool();
                }
                return;
            }

            setProgressBarLabel('Getting "Telecom Taxes & Fees" item ID from QuickBooks...');
            let taxesItemRef = {};
            try {
                const itemsList = await chimera.callQuickBooksAPI(signal, '/api/qb/items', 'POST', {whitelist: ["Telecom Taxes & Fees"]});
                if(itemsList) {
                    taxesItemRef = {
                        name: itemsList[0].item,
                        value: itemsList[0].id
                    }
                }
                else {
                    alert(`ERROR: "Telecom Taxes & Fees" item ID could not be determined. Please ensure the item exists in QuickBooks. The job cannot continue.`);
                    resetTool();
                    return;
                }
            }
            catch(e) {
                if(e.name !== "AbortError") {
                    console.error(e);
                    alert(`ERROR: An error occurred while determining the item ID for "Telecom Taxes & Fees". The job cannot continue.`);
                    resetTool();
                }
                return;
            }

            setProgressBarLabel('Getting "Net 15" term ID from QuickBooks...');
            let net15 = null;
            try {
                net15 = await chimera.callQuickBooksAPI(signal, '/api/qb/term/Net 15');
            }
            catch(e) {
                if(e.name !== "AbortError") {
                    console.warn(e);
                    banners.addBanner('warning', <span><strong>Warning: </strong> A problem occurred and the term 'Net 15' could not be applied.</span>);
                }
            }

            let qbTransactions = [];
            const now = new Date();
            let docNumber = startNumber;

            if(selectedTxns === null) {
                setProgressBarLabel(`Getting applicable recurring transactions from QuickBooks...`)
                try {
                    const transactionsByQbCustomerId = await chimera.getRecurringTransactionsBatch(signal, qbCustomers.map(qbCustomer => qbCustomer.Id), 'all');
                    for(const qbCustomerId in transactionsByQbCustomerId) {
                        let filteredTxns = transactionsByQbCustomerId[qbCustomerId].filter(txn => isTransactionBilledForVoip(txn, tspairs.map(tspair => tspair.item)));
                        if(filteredTxns.length > 0) {
                            qbTransactions.push({
                                customer: qbCustomers.find(qbCustomer => qbCustomer.Id === qbCustomerId),
                                transactions: filteredTxns
                            })
                        }
                        else {
                            let str = "";
                            let qbCustomer = qbCustomers.find(qbCustomer => qbCustomer.Id === qbCustomerId);
                            if(qbCustomer) {
                                str = qbCustomer.DisplayName;
                            }
                            else {
                                str = `QB Customer (ID: ${qbCustomerId})`
                            }
                            banners.addBanner('warning', `${str} is a VoIP customer but they have no recurring transactions in QuickBooks. They will not be processed by this tool.`, 'Skipped Customer Warning');
                        }
                    }
                }
                catch(e) {
                    if(e.name !== "AbortError") {
                        console.error(e);
                        banners.addBanner('danger', `An error occurred when reading recurring transactions`, 'QuickBooks Error');
                    }
                }
            }
            else {
                setProgressBarLabel(`Processing selected transactions...`);
                let txnsByCustomerId = {};
                for(const txn of selectedTxns) {
                    if(!txnsByCustomerId[txn.doc.CustomerRef.value]) {
                        txnsByCustomerId[txn.doc.CustomerRef.value] = [txn];
                    }
                    else {
                        txnsByCustomerId[txn.doc.CustomerRef.value].push(txn);
                    }
                }
                for(const key in txnsByCustomerId) {
                    try {
                        const customer = await chimera.callQuickBooksAPI(signal, `/api/qb/customer/${key}`);
                        qbTransactions.push({
                            customer: customer,
                            transactions: txnsByCustomerId[key]
                        })
                    }
                    catch(e) {
                        if(e.name !== "AbortError") {
                            console.error(e);
                            alert(`ERROR: An error occurred while reading the transaction. The job cannot continue.`);
                            resetTool();
                        }
                        return;
                    }
                }
            }

            let newUrls = [];
            for(const transactionGroup of qbTransactions.sort((a, b) => a.customer.DisplayName < b.customer.DisplayName ? -1 : 1)) {
                //console.log(transactionGroup);

                // Prepare Avalara API request by composing `inv` https://developer.avalara.com/communications/dev-guide_rest_v2/reference/calc-taxes-request/
                let invs = [];
                for(let i = 0; i < transactionGroup.transactions.length; i++){//const txn of transactionGroup.transactions) {
                    let itms = getAvalaraLineItemsFromQbTransaction(transactionGroup.transactions[i], tspairs, tspairQbItems);
                    if(itms.length > 0) {
                        const chimeraCustomer = findChimeraCustomerForQbCustomer(transactionGroup.customer, chimeraCustomers);
                        if(!chimeraCustomer) {
                            banners.addBanner('danger', `A Chimera Customer match could not be found for the QuickBooks customer ${transactionGroup.customer.DisplayName}. The tool relies on the Chimera Customer's Service Address, so this customer cannot be processed.`, 'Error');
                        }
                        else {
                            invs.push({
                                doc: docNumber.toString(),
                                bill: {
                                    ctry: "USA",
                                    st: chimeraCustomer.serviceAddress.state,
                                    city: chimeraCustomer.serviceAddress.city,
                                    zip: chimeraCustomer.serviceAddress.zip
                                },
                                cust: 1,
                                date: now.toISOString(),
                                itms: itms,
                                cmmt: !chimera.isDeveloperMode()
                            });
                            transactionGroup.transactions[i].newDocNumber = docNumber.toString();
                            docNumber++;
                        }
                    }
                    else {
                        banners.addBanner('warning', `No items detected in ${transactionGroup.transactions[i].type} titled "${transactionGroup.transactions[i].doc.RecurringInfo.Name}" for ${transactionGroup.customer.DisplayName}. Verify T/S pairs. The QuickBooks ${transactionGroup.transactions[i].type} will not be generated.`, 'Warning');
                    }
                }

                let maxItems = 0;
                for(const inv of invs) {
                    if(inv.itms.length > maxItems) maxItems = inv.itms.length;
                }
                if(maxItems === 0) {
                    banners.addBanner('warning', `No items detected in any transactions for ${transactionGroup.customer.DisplayName}. Verify T/S pairs. No QuickBooks transactions will be generated for this customer.`, 'Warning');
                    continue;
                }

                // Run the tax calculation
                let taxTransactions = [];
                setProgressBarLabel(`Calculating taxes for ${transactionGroup.customer.DisplayName}`);
                try {
                    const response = await chimera.callAPI(signal, '/api/avalara/calctaxes', 'POST', {
                        cmpn: {
                            bscl: 1,
                            svcl: 0,
                            fclt: false,
                            frch: false,
                            reg: true,
                        },
                        inv: invs
                    });
                    taxTransactions = response.inv;
                }
                catch(e) {
                    if(e.name !== "AbortError") {
                        console.error(e);
                        console.error(invs);
                        banners.addBanner('danger', `Failed to calculate taxes for ${transactionGroup.customer.DisplayName} - cannot generate QB Transactions for this customer`, 'Avalara API Error');
                        continue;
                    }
                    else return;
                }

                // Compose and create final transactions
                setProgressBarLabel(`Creating transaction(s) for ${transactionGroup.customer.DisplayName}`);
                for(const txn of transactionGroup.transactions) {
                    try {
                        const url = await createTransaction(transactionGroup, txn, taxTransactions, net15, taxesItemRef, chimeraCustomers);
                        newUrls.push(url);
                    }
                    catch(e) {
                        if(e.name !== "AbortError") {
                            console.error(e);
                            banners.addBanner('danger', `Failed to create Transactions for ${transactionGroup.customer.DisplayName} - please ensure this customer has a valid Primary Email Address`, 'QuickBooks Error');
                            continue;
                        }
                        else return;
                    }
                }
            }

            setUrls(newUrls);

            // Save the new starting number
            await chimera.callAPI(signal, '/api/attributes', 'PUT', {name: "DOCNUMBER_START", value: docNumber});

            setShowProgressBar(false);
            setShowResults(true);
        }
        catch(err) {
            console.error(err);
            banners.addBanner('danger', 'An unhandled error occurred and the transaction(s) could not be created.', 'Error');
        }
    }

    const isTransactionBilledForVoip = (txn, voipItemNames) => {
        let isBilled = false;
        for(const line of txn.doc.Line) {
            if(line.DetailType === "SalesItemLineDetail") {
                for(const voipItemName of voipItemNames) {
                    if(line.SalesItemLineDetail.ItemRef.name.includes(voipItemName)) {
                        isBilled = true;
                        break;
                    }
                }
            }
        }
        return isBilled;
    }

    const presentCustomersChoice = async() => {
        setShowGoBtn(false);
        setShowProgressBar(true);
        setProgressBarLabel("Loading Transactions...");
        try {
            let chimeraCustomers = await getChimeraCustomers();
            const txnBatch = await chimera.getRecurringTransactionsBatch(signal, chimeraCustomers.map(customer => customer.integrationIds.quickbooks), 'all', 'all');
            const tspairs = await chimera.callAPI(signal, '/api/tspairs');
            let txns = [];
            for(const key in txnBatch) {
                for(const txn of txnBatch[key]) {
                    txns.push(txn);
                }
            }
            txns = txns.sort((a, b) => a.doc.CustomerRef.name < b.doc.CustomerRef.name ? -1 : 1);
            txns = txns.filter(txn => isTransactionBilledForVoip(txn, tspairs.map(tspair => tspair.item)) && !BLACKLIST.includes(txn.doc.CustomerRef.value));
            console.log(txns);
            setTxns(txns);
            setShowTxnChoice(true);
            setShowProgressBar(false);
        }
        catch(e) {
            if(e.name !== "AbortError") {
                console.error(e);
                alert(`ERROR: An error occurred while loading customers. The job cannot continue.`);
                resetTool();
            }
            return;
        }
    }

    const clickedTxn = (txn) => {
        const link = document.createElement('a');
        link.href = `https://qbo.intuit.com/app/recurringinvoice?templateAction=GET&txnId=${txn.doc.Id}`;
        link.target = '_blank';
        link.rel = "noopener noreferrer";
        link.click();
    }

    const renderTxnChoice = () => {
        return(
            <div className="mb-3 centered">
                {txns.length > 0 ? 
                <ObjectTable 
                    id="taxCalcChoiceTable"
                    cols={[
                        {
                            label: 'Type', 
                            sort: (ascending) => {
                                return (a, b) => {
                                    const values = {
                                        'Invoice': 0,
                                        'SalesReceipt': 1,
                                    }
                                    return values[`${a.type}`] < values[`${b.type}`] ? (ascending ? -1 : 1) : (ascending ? 1 : -1);
                                }
                            }
                        },
                        {
                            label: 'Customer Name',
                            sort: (ascending) => {
                                return (a, b) => a.doc.CustomerRef.name < b.doc.CustomerRef.name ? (ascending ? -1 : 1) : (ascending ? 1 : -1)
                            }
                        },
                        {
                            label: 'Template Name',
                            sort: (ascending) => {
                                return (a, b) => a.doc.RecurringInfo.Name < b.doc.RecurringInfo.Name ? (ascending ? -1 : 1) : (ascending ? 1 : -1)
                            }
                        },
                        {
                            label: 'Paused?',
                            sort: (ascending) => {
                                return (a, b) => {
                                    const values = {
                                        true: 0,
                                        false: 1,
                                    }
                                    return values[`${a.doc.RecurringInfo.Active.toString()}`] < values[`${b.doc.RecurringInfo.Active.toString()}`] ? (ascending ? -1 : 1) : (ascending ? 1 : -1);
                                }
                            }
                        },
                    ]}
                    objects={txns}
                    btns={[
                        {label: 'Back', icon: 'fas fa-arrow-left', color: 'secondary', func: (_) => {resetTool();}},
                        {label: 'Run Selected Transactions', func: (selectedTxns => runJob(selectedTxns)), icon: 'fas fa-arrow-right', color: 'primary'}
                    ]}
                    rowElement={TxnRow}
                    clickedObject={clickedTxn}
                    search
                    paginated
                    defaultSortByColName="Customer Name"
                    defaultSortAscending={true}
                    selectable
                    idPath='doc.Id'
                />
                : <h4>There don't seem to be any VoIP customers with QuickBooks integrations.</h4>}
            </div>
        )
    }

    return (
        <>
            <div className="col-lg-12">
                {showGoBtn ? 
                <div className="mb-3 centered">
                    <div className="row mb-3 mt-3">
                        <h4>
                            Choose an Option:
                        </h4>
                    </div>
                    <div className="row mb-3 mt-3">
                        <div className="col-4"></div>
                        <div className="col-4">
                            <button className="btn btn-primary w-100" onClick={() => {runJob(null)}}>
                                Process All Transactions
                            </button>
                        </div>
                        <div className="col-4"></div>
                    </div>
                    <div className="row mb-3 mt-3">
                        <div className="col-4"></div>
                        <div className="col-4">
                            <button className="btn btn-primary w-100" onClick={presentCustomersChoice}>
                                Process Select Transactions
                            </button>
                        </div>
                        <div className="col-4"></div>
                    </div>
                </div>
                :null}
                {showProgressBar ?
                    <LoadingSpinner size={100} label={progressBarLabel}/>
                : null}
                {showResults ? 
                    <>
                    {urls.length > 0 ?
                        <div className="text-start mt-3">
                            <button className="btn btn-primary" onClick={emailUrls} disabled={emailBtnDisabled}>
                                <i className={emailBtnIcon}/>
                                &nbsp;{emailBtnLabel}
                            </button>
                            &nbsp;&nbsp;{emailResponseText}
                            <div id="urls" className="mt-3">
                                {renderUrls()}
                            </div>
                        </div>
                    :
                        <h4>It seems that no transactions were successfully created. :(</h4>
                    }
                    <div className="text-start mt-3">
                        <button className="btn btn-secondary" onClick={resetTool}>
                            <i className="fas fa-arrow-left"/>
                            &nbsp;Start Over
                        </button>
                    </div>
                    </>
                :null}
                {showTxnChoice ? renderTxnChoice() : null}
            </div>
        </>
    );
}

const TaxCalc = props => {
    const toolName = "Tax Calculator";
    const toolId = "taxcalc";
    return (
        <ToolPage toolId={toolId} toolName={toolName}>
            <ToolPage.Header image={taxcalcimg} alt="Arrow from Avalara to QuickBooks. The IRS is happy." toolName={toolName}>
                A utility for adding VoIP taxes to recurring QuickBooks transactions before they are published and processed.
            </ToolPage.Header>
            <ToolPage.How>
                <h3>
                    Preparation
                </h3>
                <p>
                    This tool takes advantage of careful configuration within both QuickBooks and Avalara.
                </p>
                <h4>
                    QuickBooks
                </h4>
                <p>
                    This tool uses a customer's recurring transactions, which is just one type of Recurring Transaction you
                    can set up within QuickBooks. <strong>It is recommended that you "pause" these transactions</strong>, otherwise QuickBooks
                    will automatically send them out without tax charges on them! The tool will read these transactions and find matches
                    in Avalara for calculating taxes.
                </p>
                <h4>
                    Avalara
                </h4>
                <p>
                    This tool is designed to work with Bundles in Avalara, which define a single T/S pair that corresponds to a distribution
                    of taxes. <strong>If the item name on an transaction matches a bundle in Avalara, then it will be included in the tax calculation.</strong>
                </p>
                <h3>
                    Usage
                </h3>
                <p>
                    At the start, you will presented with two choices: <strong>Process All Transactions</strong> and <strong>Process Single Transaction.</strong>.
                    In most cases you will want to use <strong>Process All Transactions</strong>, but <strong>Process Single Transaction</strong> is available
                    in case something goes wrong and you need to retry with one or two transactions.
                </p>
                <p>
                    The <strong>Process All Transactions</strong> option is optimized such that it only makes 2 API calls to Avalara, regardless
                    of how many transactions are being processed: one to read T/S pairs to match against line items, and one to actually calculate the taxes, which it does
                    for all transactions at once before assigning tax charges to their individual transactions after the fact.
                </p>
                <p>
                    The <strong>Process Single Transaction</strong> option will perform the 2 necessary API calls, but only for the one transaction, which is ineffecient.
                    Please use this option sparingly.
                </p>
                <p>
                    As this tool uses Recurring Transactions and not existing transactions, it is very difficult to detect potential duplicates, and so no such detection
                    is performed. <strong>Therefore, if you run Process All Transactions twice in a row, duplicate transactions will be created, albeit with different numbers.</strong> This
                    is why the <strong>Process Single Transaction</strong> option exists: if one transaction fails, you can try it again by itself without creating a mess of duplicates.
                </p>
                <h4>
                    Process All Transactions
                </h4>
                <ol>
                    <li>
                        <strong>Click <i>Process All Transactions</i>.</strong> Automatically, the job begins.
                    </li>
                    <li>
                        <strong>Wait patiently for the job to finish.</strong> This will grab all eligible customers, grab their recurring transactions, and
                        process each transaction by creating a new, real transaction with line items for each tax charge.
                    </li>
                    <li>
                        <strong>(Optional) Click <i>Email Me</i>.</strong> When the results appear, you can click this button to receive an email containing the list
                        of links to the transactions straight to the email associated with the account you used to log in.
                    </li>
                </ol>
                <h4>
                    Process Single Transaction
                </h4>
                <ol>
                    <li>
                        <strong>Click <i>Process Single Transaction</i>.</strong> The tool will load the list of eligible customers from QuickBooks for you to select.
                    </li>
                    <li>
                        <strong>Click on the customer.</strong> The tool will load the list of recurring transactions (only transactions, no other type of transaction) for the
                        customer you select.
                    </li>
                    <li>
                        <strong>Click on the transaction.</strong> Each transaction has an ID, which is the internal ID that QuickBooks uses to identify that Recurring Transaction.
                        If you're editing the transaction, you can actually see it at the end of the URL (where it says <u>txnId=<i>xyz</i></u>, the <u><i>xyz</i></u> is the ID).
                        This ID is displayed in a warning if an issue arises while the tool attempts to process it. If you take note of the warning, you should be able to find
                        the exact transaction you need to re-process in this way.
                    </li>
                    <li>
                        <strong>Wait patiently for the job to finish.</strong> This should be faster since only one transaction is being processed. At the end, you will see the same
                        results screen as when running <strong>Process Single Transaction</strong>.
                    </li>
                </ol>
            </ToolPage.How>
            <ToolPage.Body>
                <TaxCalcBody/>
            </ToolPage.Body>
        </ToolPage>
    );
}

export default TaxCalc;