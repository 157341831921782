import React, {useState, useEffect, useLayoutEffect} from 'react';

import chimera from '../chimera';
import ModalCancelOnly from './ModalCancelOnly';
import FormFieldMicro from './FormFieldMicro';

const AddOptionModal = ({listName, options, setOptionCallback, modalContext}) => {
    const [option, setOption] = useState("");
    const [isSaving, setIsSaving] = useState(false);

    const handleChange = (event) => {
        event.preventDefault();
        setOption(event.target.value);
    }

    const trimOnBlur = (event) => {
        handleChange({
            target: {
                type: "string",
                name: event.target.name,
                value: event.target.value.trim()
            },
            preventDefault: () => {}
        })
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsSaving(true);

        chimera.setExpandableDropdownOptions(listName, [...options, option])
        .then(_ => {
            setIsSaving(false);
            setOptionCallback(option);
            modalContext.backtrack();
        })
        .catch(err => {
            console.error(err);
            alert("ERROR: Failed to save new Vendor");
            setIsSaving(false);
        });
    }
    
    return(
        <ModalCancelOnly context={modalContext} backtrack>
            <div className="row">
                <div className="col">
                    <FormFieldMicro
                        type="text"
                        name="option"
                        label="New Option"
                        value={option}
                        handleChange={handleChange}
                        onBlur={trimOnBlur}
                        fit
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <button className="btn btn-primary mb-2" onClick={handleSubmit} disabled={isSaving}>
                        <i className={isSaving ? "fas fa-spinner" : "fas fa-plus"}/>&nbsp;{isSaving ? "Saving..." : "Add Option"}
                    </button>
                </div>
            </div>
        </ModalCancelOnly>
    )
}

const ExpandableDropdown = ({listName, htmlName, handleChange, required, disabled, value, banners, modalContext}) => {
    const [options, setOptions] = useState(null);
    const [controller] = useState(new AbortController());
    const [signal] = useState(controller.signal);

    const _handleChange = (event) => {
        event.preventDefault();
        if(event.target.value === "ADD_NEW_OPTION") {
            // Open modal, forward new selection `handleChange`
            openAddOptionModal();
        }
        else {
            handleChange({
                target: {
                    type: "string",
                    name: event.target.name,
                    value: event.target.value.trim()
                },
                preventDefault: () => {}
            })
        }
    }

    const openAddOptionModal = () => {
        // Prompt for the name of the new option and then assign it to the value
        const setOptionCallback = (option) => {
            setOptions([...options, option]);
            handleChange({
                target: {
                    type: "string",
                    name: htmlName,
                    value: option
                },
                preventDefault: () => {}
            })
        }
        modalContext.setModal(<AddOptionModal listName={listName} modalContext={modalContext} options={options} setOptionCallback={setOptionCallback}/>);
    }

    useLayoutEffect(() => {
        return () => {
            controller.abort();
        }
    }, []);

    useEffect(() => {
        if(options === null) {
            chimera.getExpandableDropdownOptions(listName, signal)
            .then(opts => setOptions(opts))
            .catch(err => {
                console.error(err);
                if(banners) {
                    banners.addBanner('danger', `Could not read options for ${listName}; the field will be disabled`, 'Error');
                }
                else {
                    alert(`ERROR: Could not read options for ${listName}; the field will be disabled`);
                }
            })
        }
    }, [options]);

    return (
        <>
        {options ? 
        <select className="form-select" name={htmlName} onChange={_handleChange} required={required} disabled={disabled || options === null} value={value}>
                <option value="NOT SET">-- None Selected --</option>
                {options.map((opt, i) => <option key={i} value={opt}>{opt}</option>)}
                <option value="ADD_NEW_OPTION">+ Add New Option...</option>
        </select>
        :
        <i className="fas fa-spinner"/>
        }
        </>
    )
}

export default ExpandableDropdown