import React, {useState} from 'react';

/**
 * A pagination interface
 * @param {number} page the selected page (1-indexed)
 * @param {function} setPage a function to set `page`
 * @param {number} nPages the number of pages
 * @param {number} perPage the number of entries per page
 * @param {function} setPerPage a function to set `perPage`
 */
const Pagination = ({page, setPage, nPages, perPage, setPerPage}) => {
    let btns = [];
    for(let i = 1; i <= nPages; i++) {
        btns.push(
            <li key={i} className={i === page ? "page-item active" : "page-item"}>
                <a className="page-link" href="#" onClick={(event) => {event.preventDefault(); setPage(i)}}>
                    {i}
                </a>
            </li>
        )
    }
    const handlePerPageChange = (e) => {
        e.preventDefault();
        setPerPage(e.target.value);
    }
    return (
        <>
        {nPages === 0 ? null : 
        <nav aria-label="Customer page navigation">
            <div className="d-flex flex-row">
                <ul className="pagination">
                    <li className={page !== 1 ? "page-item" : "page-item disabled"}>
                        {page !== 1 ?
                            <a className="page-link" href="#" aria-label="Previous" onClick={(event) => {event.preventDefault(); setPage(page-1)}}>
                                <span aria-hidden="true">&laquo;</span>
                            </a>
                        :
                            <span className="page-link" aria-hidden="true">&laquo;</span>
                        }
                    </li>
                    {btns}
                    <li className={page !== nPages ? "page-item" : "page-item disabled"}>
                        {page !== nPages ? 
                            <a className="page-link" href="#" aria-label="Next" onClick={(event) => {event.preventDefault(); setPage(page+1)}}>
                                <span aria-hidden="true">&raquo;</span>
                            </a>
                        :
                            <span className="page-link" aria-hidden="true">&raquo;</span>
                        }
                    </li>
                </ul>
                {perPage && setPerPage ? 
                <select className="form-select w-fit mb-3 ms-1" name="perPage" onChange={handlePerPageChange} value={perPage}>
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={-1}>All</option>
                </select>
                :null}
            </div>
        </nav>
        }
        </>
    )
}

export default Pagination;