import React, {useState, useEffect, useLayoutEffect, useContext} from 'react';
import chimera from '../../chimera';
import BannerContext, { BannerLog } from '../BannerLogContext';
import LoadingSpinner from '../LoadingSpinner';
import ReportTable from '../ReportTable';

const EndpointsLastSeenBody = props => {
    const [loading, setLoading] = useState(true);
    const [label, setLabel] = useState("Running... this may take several minutes...");
    const [report, setReport] = useState(null);
    const [saveBtnIcon, setSaveBtnIcon] = useState("fas fa-floppy-disk");
    const [saveBtnLabel, setSaveBtnLabel] = useState("Save to Chimera");
    const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
    const [controller, setController] = useState(new AbortController());
    const [signal, setSignal] = useState(controller.signal);
    const banners = useContext(BannerContext);

    useLayoutEffect(() => {
        return () => {
            controller.abort();
        }
    }, []);

    useEffect(() => {
        chimera.callAPI(signal, '/api/reports/endpointslastseen/run')
        .then(response => {
            setReport(response);
            setLoading(false);
        })
        .catch(e => {
            if(e.name !== "AbortError") {
                console.error(e);
                setLabel("Error");
                banners.addBanner('danger', 'Failed to generate the report', 'Error');
            }
        })
    }, []);

    const saveReport = async(event) => {
        event.preventDefault();
        setSaveBtnIcon("fas fa-spinner");
        setSaveBtnDisabled(true);
        setSaveBtnLabel("Saving...");
        try {
            await chimera.callAPI(signal, '/api/reporttables', 'POST', report);
            setSaveBtnIcon("fas fa-check");
            setSaveBtnLabel("Saved!");
        }
        catch(e) {
            if(e.name !== "AbortError") {
                console.error(e);
                banners.addBanner('danger', 'Failed to save report', 'Error');
                setSaveBtnDisabled(false);
                setSaveBtnIcon("fas fa-floppy-disk")
            }
        }
    }

    return (
        <>
            <h1>Endpoints Last Seen</h1>
            {loading ? 
                <LoadingSpinner size={50} label={label}/>
            :
                <>
                    <div className="row mb-2">
                        <div className="col">
                            <button className="btn btn-primary float-start" onClick={saveReport} disabled={saveBtnDisabled}>
                                <i className={saveBtnIcon}/>
                                &nbsp;{saveBtnLabel}
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <ReportTable report={report}/>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

const EndpointsLastSeen = props => {
    return(
        <BannerLog>
            <EndpointsLastSeenBody />
        </BannerLog>
    )
}

export default EndpointsLastSeen;