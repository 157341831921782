import React, {useState, useContext} from 'react';
import BannerContext, { BannerLog } from '../BannerLogContext';
import InternetTab from './InternetTab';
import ProductsTab from './ProductsTab';
import VoipTab from './VoipTab';
import MSPTab from './MSPTab';
import AlertsContext from "../AlertsContext";
import AlertBubble from '../AlertBubble';
import { useParams, NavLink } from 'react-router-dom';

const supportedTypes = ['products', 'internet', 'voip', 'msp'];

const OrdersBody = props => {
    const { type } = useParams();
    const [tab, setTab] = useState(type && supportedTypes.includes(type) ? type : 'products');
    const banners = useContext(BannerContext);
    const alertsContext = useContext(AlertsContext);

    const Tab = props => {
        const lowercase = props.name.toLowerCase();
        return(
            <li className="nav-item">
                <NavLink className="nav-link" to={`/orders/${lowercase}`} onClick={() => {setTab(lowercase)}}>
                    {props.name}{Bubble(props.name)}
                </NavLink>
            </li>
        )
    }

    const Bubble = (tabName) => {
        if(alertsContext.alerts === null) {
            return null;
        }
        else {
            let arrayName;
            switch(tabName){
                case 'Internet':
                    arrayName = 'internetOrders';
                    break;
                case 'Products':
                    arrayName = 'productOrders';
                    break;
                case 'VoIP':
                    arrayName = 'voipOrders';
                    break;
                case 'MSP':
                    arrayName = 'mspOrders';
                    break;
            }
            if(!arrayName) return null;

            // `alertsContext.alerts.orders[arrayName]` is an object, not an array
            const keys = Object.keys(alertsContext.alerts.orders[arrayName]);
            let sum = 0;
            for(const key of keys) {
                sum += alertsContext.alerts.orders[arrayName][key].length;
            }

            if(sum > 0) {
                return <>&nbsp;<AlertBubble inline>{sum}</AlertBubble></>
            }
            else {
                return null;
            }
        }
    }

    const tabBody = () => {
        switch(tab) {
            case 'internet':
                return <InternetTab bannerContext={banners}/>
            case 'products':
                return <ProductsTab/>
            case 'voip':
                return <VoipTab bannerContext={banners}/>
            case 'msp':
                return <MSPTab bannerContext={banners}/>
            default:
                return <p className="text-muted">Well, this is awkward. This tab doesn't exist. Please report this error to the developer.</p>
        }
    }

    return (
        <>
            <ul className="nav nav-tabs mb-3">
                <Tab name="Products"/>
                <Tab name="Internet"/>
                <Tab name="VoIP"/>
                <Tab name="MSP"/>
            </ul>
            {tabBody()}
        </>
    )
}

const Orders = props => {
    return (
        <div className="container pb-5">
            <h1>
                Orders
            </h1>
            <BannerLog>
                <OrdersBody/>
            </BannerLog>
        </div>
    )
}

export default Orders;