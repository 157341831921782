import React, {useState, useEffect, useContext} from "react";
import LoginPage from "./LoginPage";
import chimeraimg from "../images/chimeraimg.png";
import UserContext from "../UserContext";
import chimera from "../chimera";
import PatchNotesDisplay from "./PatchNotesDisplay";
import LoadingSpinner from "./LoadingSpinner";
import { NavLink } from "react-router-dom";

const Home = props => {
    const [latestNotes, setLatestNotes] = useState(null);
    const context = useContext(UserContext);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        chimera.callAPI(signal, '/api/patchnotes/latest')
        .then(latest => setLatestNotes(latest))
        .catch(err => {
            console.error(err);
        })
        return () => {
            controller.abort();
        }
    }, []);

    return (
        <div className="home">
            <div className="container pb-5">
                {context.user ? 
                    <>
                    <div className="row align-items-center py-3">
                        <div className="col-lg-6">
                            <img
                                className="img-fluid rounded mb-4 mb-lg-0"
                                src={chimeraimg}
                                alt="Chimera"
                            />
                        </div>
                        <div className="col-lg-6">
                            <h1 className="chomsky">Welcome to Chimera</h1>
                            <p>
                                Chimera BMS (or just Chimera, or BMS, or whatever) is a Business
                                Management System for CBIT.
                            </p>
                            <p>
                                In Greek mythology, the Chimera was a monster composed of incongruous
                                animal parts (often the head of a lion, goat, and serpent) on a single body.
                                CBIT's Chimera BMS also works to bring the heads of Internet, VoIP and IT
                                Services to a single body, acting as a centralized control point for monitoring
                                and maintaining the multifaceted scope of our business.
                            </p>
                        </div>
                    </div>
                    <h2>What's New?</h2>
                    <NavLink to="/patchnotes">
                        View all patch notes <i className="fas fa-arrow-right"/>
                    </NavLink>
                    {latestNotes ? 
                        <PatchNotesDisplay notes={latestNotes}/>
                    :
                        <LoadingSpinner size={50}/>
                    }
                    </>
                : 
                    <LoginPage tool="the Toolbox"/>
                }
            </div>
        </div>
    );
}

export default Home;